
import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { appConfig } from "../../../config";
@Injectable({
  providedIn: "root"
})
export class UserInactiveService {
  idleTimeMins: number = 1000 * 60;
  timerHandle: any;
  timerSubscription: Subscription;
  timeoutExpired: Subject<any> = new Subject();
  
  constructor() {
    this.idleTimeMins = this.idleTimeMins * appConfig.allowedIdleTimeMins;
    this.startTimer();
  }

  /**
   * Start Timer
   * @returns void
   */
  startTimer(): void {
    this.UnsubscribeTimer();
    this.attachEvents();
  }
  /**
   * Reset Timer Whenever User Start Interacting with Application
   * * @returns void
   */
  attachEvents(): void {
    window.onload = this.resetTimer.bind(this);
    document.onmousemove = this.resetTimer.bind(this);
    document.onkeypress = this.resetTimer.bind(this);
  }
  /**
   * Stop Timer
   * @returns void
   */
  stopTimer(): void {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = null;
    }
  }
  /**
   * User Action found. Reset Timer
   * @returns void
   */
  resetTimer(): void {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = null;
    }

    this.timerHandle = setTimeout(() => {
      const minutes = this.idleTimeMins.fromMilli2Minutes();

      this.timeoutExpired.next(minutes);
    }, this.idleTimeMins);
  }
  /**
   * Release Subscription
   * @returns void
   */
  UnsubscribeTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
