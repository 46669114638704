
import { DatePipe } from "@angular/common";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NVCommonModule } from "@pebt/common";

import { HttpServiceModule } from "@pebt/http";
import { ConfirmDialogModule } from "@pebt/primeng";
import { StorageMedium, StorageModule } from "@pebt/storage";
import {
  CheckKeyIdentiferGuard,
  CheckUnSavedDataGuard,
  UibaseModule
} from "@pebt/uibase";
import { AccessGuard } from "@shared/guard/access.guard";
import { HttpRequestInterceptor } from "@shared/guard/httprequestinterceptor";
import { UserInactiveService } from "@shared/services/user-inactive.service";
import { appConfig } from "../config";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    

    SharedModule,
    NVCommonModule,
    ConfirmDialogModule,
    UibaseModule,

    
    HttpServiceModule.forRoot({
      apiUrl: appConfig.apiConfig.apiEndPoint,
      serviceRetryLimit: appConfig.apiConfig.serviceRetryCount,
      timeToReconnect: appConfig.apiConfig.serviceRetryDelay,
      service2Cache: appConfig.apiConfig.service2Cache,
      httpIdleTimeMin:
        appConfig.apiConfig.sessionTimeOut -
        appConfig.apiConfig.sessionWarningTime
    }),
    StorageModule.forRoot({
      applicationName: appConfig.applicationName,
      storageMedium: StorageMedium.sessionStorage
    })
    
  ],
  providers: [
    CheckUnSavedDataGuard,
    CheckKeyIdentiferGuard,
    UserInactiveService,
    DatePipe,
    AccessGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}


