<ng-container *ngIf="!isTable">

    <div class="p-grid">
        <div class="gridHeader p-col-12 no-pad">
            <ng-container *ngFor="let col of gridColumns">
                <div class="gridColumnStyle " [class.hideOnMobile]="col.hideOnMobile == true"
                    [class.hideOnTab]="col.hideOnTab == true" [ngStyle]="{'width':getColWidth(col)  + '%'}">
                    <b> {{col.header}}</b>

                </div>
            </ng-container>
            <!-- [style.width.%]="colWidth"-->
            <div class=" gridColumnStyle" *ngIf="isEditable" [style.width.%]="colWidth"></div>

        </div>
        <div class="gridRow  p-col-12 no-pad" *ngFor="let record of pageData">
            <div class="p-grid">
                <ng-container *ngFor="let col of gridColumns">
                    
                    <div class="gridColumnStyle {{col.className}}" [class.hideOnMobile]="col.hideOnMobile == true"
                        [class.hideOnTab]="col.hideOnTab == true" [ngStyle]="{'width':getColWidth(col)  + '%'}">

                        <ng-container *ngIf="!col.templateType && !col.template && !col.options">
                            {{record[col.field]}}
                        </ng-container>


                        <!-- templateType -->

                        <ng-container *ngIf="col.templateType == 2">
                            <!-- currency  -->
                            {{record[col.field] | currency:'USD':true:'1.2-2'}}
                        </ng-container>

                        <ng-container *ngIf="col.templateType == 4">
                            <!-- link -->
                            <a (click)="onLinkClick($event,record,col)"> {{record[col.field]}} </a>
                        </ng-container>

                        <ng-container *ngIf="col.templateType == 6">
                            <!-- date -->
                            {{record[col.field]| dateAlone |date: "MM/dd/yyyy"}}
                        </ng-container>

                        <ng-container *ngIf="col.templateType == 5">
                            <!-- refm -->

                            {{record[col.field] | refmpipe: col.tableId+col.subTableId}}
                        </ng-container>

                        <ng-container *ngIf="col.templateType == 9">
                            <!-- phone -->

                            {{record[col.field] | phone}}
                        </ng-container>

                        <ng-container *ngIf="col.templateType == 12">
                            <!-- ssn -->

                            {{record[col.field] | ssn}}
                        </ng-container>

                        <ng-container *ngIf="col.templateType == 13">
                            <!-- zip -->

                            {{record[col.field] | zip}}
                        </ng-container>

                        <!-- template -->
                        <ng-container *ngIf="col.template">
                            <ng-container
                                *ngTemplateOutlet="col.template;context: { $implicit: record , column : col }">
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="col.options">
                            {{record[col.field] | optionpipe: col.options}}
                        </ng-container>


                    </div>

                    <!-- <div class="accordionArrow" *ngIf="isTabView||isMobileView"
                        [hidden]="col.hideOnTab != true && col.hideOnMobile != true"
                        (click)="record.showAccordion = !record.showAccordion">
                        <i class="fa fa-caret-down" *ngIf="record.showAccordion==false"></i>
                        <i class="fa fa-caret-up" *ngIf="record.showAccordion==true"></i>
                    </div> -->
                </ng-container>

                <div class="accordionArrow" *ngIf="(isTabView||isMobileView)&& needAccIcon"
                    (click)="record.showAccordion = !record.showAccordion">
                    <i class="fa fa-caret-down" *ngIf="record.showAccordion==false"></i>
                    <i class="fa fa-caret-up" *ngIf="record.showAccordion==true"></i>
                </div>

                <div class="gridActionColumn gridColumnStyle" *ngIf="isEditable" [style.width.%]="colWidth">
                    <i pTooltip="Edit" tooltipPosition="bottom" class="fa fa-pencil gridEdit"
                        (click)="onEditClick(record)"></i>
                    <i pTooltip="Delete" tooltipPosition="bottom" class="fa fa-close gridDelete"
                        (click)="onDeleteClick(record)"></i>
                </div>
            </div>
            <div class="gridAccordion" *ngIf="record.showAccordion== true">
                <ng-container *ngFor="let col of gridColumns">
                    <div class=""
                        *ngIf="(isTabView &&col.hideOnTab == true) || (isMobileView && (col.hideOnTab == true || col.hideOnMobile == true))">
                        <div><b>{{col.header}} : </b>
                            <ng-container *ngIf="!col.templateType && !col.template && !col.options">
                                {{record[col.field]}}
                            </ng-container>

                            <!-- templateType -->

                            <ng-container *ngIf="col.templateType == 2">
                                <!-- currency  -->
                                {{record[col.field] | currency:'USD':true:'1.2-2'}}
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 4">
                                <!-- link -->
                                <a (click)="onLinkClick($event,record,col)"> {{record[col.field]}} </a>
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 6">
                                <!-- date -->
                                {{record[col.field]|dateAlone |date: "MM/dd/yyyy"}}
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 5">
                                <!-- refm -->

                                {{record[col.field] | refmpipe: col.tableId+col.subTableId}}
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 9">
                                <!-- phone -->

                                {{record[col.field] | phone}}
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 12">
                                <!-- ssn -->

                                {{record[col.field] | ssn}}
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 13">
                                <!-- zip -->

                                {{record[col.field] | zip}}
                            </ng-container>

                            <!-- template -->
                            <ng-container *ngIf="col.template">
                                <ng-container
                                    *ngTemplateOutlet="col.template;context: { $implicit: record , column : col }">
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="col.options">
                                {{record[col.field] | optionpipe: col.options}}
                            </ng-container>

                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

    </div>
    <div class="p-col-12 customPaginator" *ngIf="paginator && (gridData.length > rowsPerPage)">
        <br />
        <p-paginator [rows]="rowsPerPage" [totalRecords]="gridData.length" (onPageChange)="paginate($event)">
        </p-paginator>

    </div>
</ng-container>

<!-- <ng-container *ngIf="isTable && pageData.length >0"> -->
<ng-container *ngIf="false">
    <table style="width:100%;border: 1;margin-bottom:1rem;">
        <ng-container *ngFor="let col of gridColumns">
            <th [style.width.%]="colWidth" style="text-align: left;padding-bottom:15px;padding-top:15px;">
                <b> {{col.header}}</b>
            </th>
        </ng-container>
        <tr *ngFor="let record of pageData">
            <td *ngFor="let col of gridColumns" [style.width.%]="colWidth"
                style="border-bottom:1px solid #f2f2f2;padding:10px 1px;">
                <ng-container *ngIf="!col.templateType && !col.template && !col.options">
                    {{record[col.field]}}
                </ng-container>


                <!-- templateType -->

                <ng-container *ngIf="col.templateType == 2">
                    <!-- currency  -->
                    {{record[col.field] | currency:'USD':true:'1.2-2'}}
                </ng-container>

                <ng-container *ngIf="col.templateType == 4">
                    <!-- link -->
                    <a (click)="onLinkClick($event,record,col)"> {{record[col.field]}} </a>
                </ng-container>

                <ng-container *ngIf="col.templateType == 6">
                    <!-- date -->
                    {{record[col.field] | dateAlone | date: "MM/dd/yyyy"}}
                </ng-container>

                <ng-container *ngIf="col.templateType == 5">
                    <!-- refm -->

                    {{record[col.field] | refmpipe: col.tableId+col.subTableId}}
                </ng-container>

                <ng-container *ngIf="col.templateType == 9">
                    <!-- phone -->

                    {{record[col.field] | phone}}
                </ng-container>

                <ng-container *ngIf="col.templateType == 12">
                    <!-- ssn -->

                    {{record[col.field] | ssn}}
                </ng-container>

                <ng-container *ngIf="col.templateType == 13">
                    <!-- zip -->

                    {{record[col.field] | zip}}
                </ng-container>

                <!-- template -->
                <ng-container *ngIf="col.template">
                    <ng-container *ngTemplateOutlet="col.template;context: { $implicit: record , column : col }">
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="col.options">
                    {{record[col.field] | optionpipe: col.options}}
                </ng-container>


            </td>
        </tr>

    </table>
</ng-container>


<!-- <ng-container *ngIf="isTable && pageData.length >0"> -->
<ng-container *ngIf="false">
    <table width="100%" style="padding:10px 1px;">

        <tr *ngFor="let record of pageData;let i = index">
            <td style="border:1px solid #f2f2f2;padding:5px 3px;" [ngStyle]="{'border-top-width':(i == 0)?'1px':'0px'}">
                <table>
                    <tr *ngFor="let col of gridColumns">
                        <td> {{col.header}} </td>
                        <td>:</td>
                        <td>
                            <ng-container *ngIf="!col.templateType && !col.template && !col.options">
                                {{record[col.field]}}
                            </ng-container>


                            <!-- templateType -->

                            <ng-container *ngIf="col.templateType == 2">
                                <!-- currency  -->
                                {{record[col.field] | currency:'USD':true:'1.2-2'}}
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 4">
                                <!-- link -->
                                <a (click)="onLinkClick($event,record,col)"> {{record[col.field]}} </a>
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 6">
                                <!-- date -->
                                {{record[col.field] | dateAlone | date: "MM/dd/yyyy"}}
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 5">
                                <!-- refm -->

                                {{record[col.field] | refmpipe: col.tableId+col.subTableId}}
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 9">
                                <!-- phone -->

                                {{record[col.field] | phone}}
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 12">
                                <!-- ssn -->

                                {{record[col.field] | ssn}}
                            </ng-container>

                            <ng-container *ngIf="col.templateType == 13">
                                <!-- zip -->

                                {{record[col.field] | zip}}
                            </ng-container>

                            <!-- template -->
                            <ng-container *ngIf="col.template">
                                <ng-container
                                    *ngTemplateOutlet="col.template;context: { $implicit: record , column : col }">
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="col.options">
                                {{record[col.field] | optionpipe: col.options}}
                            </ng-container>


                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</ng-container>

<div style="padding:10px 1px 15px 1px;" *ngIf="isTable && template && pageData.length >0">
    <div  *ngFor="let record of pageData;let i = index" style="width:97%;border:1px solid #f2f2f2;padding:10px;display: inherit;"  
    [ngStyle]="{'border-top-width':(i == 0)?'1px':'0px'}" class="fullWidth">
        <table cellspacing="0" cellpadding="0" >
            <ng-container *ngTemplateOutlet="template;context: { $implicit: record }">
            </ng-container>
        </table>

    </div>
</div>