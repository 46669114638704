import { BaseModel } from "@pebt/common";

export class AddressInfo extends BaseModel {
    Line1Addr: string = "";
    Line2Addr: string = "";
    CityAddr: string = "";
    StateAddr: string = "";
    CountyIdno: string = "";
    ZipAddr: string = "";
    NormalizationCode: string = "N";
}
