<div #userInfoHeader class="userInfoHeader cursorPointer pull-right {{ mode }}" (click)="userInfoPanel.toggle($event)">
  <!-- <lib-image class="pull-left" [service]="profileImageService | async" styleClass="loginUserprofileImage" alt="Img">
  </lib-image> -->
  <span class="nv-worker"></span>
  <!-- <img class="pull-left"  style="    " /> -->
  <div class="pull-left userInfo">
    <div class="userName" [attr.title]="userInfo">
        {{userInfo}}
    </div>
    <div class="no-pad userDtlsContainer">
      <div>{{ messageService.signedOnWorkerId }}</div>
      <div>
        {{ dateTime | date: "MM/dd/yyyy hh:mm:ss a" }}
        <span>- </span>
        <span class="no-pad VersionStyle">Build 1.0 : {{ mode }}</span>
      </div>
    </div>
  </div>
  <lib-link styleClass="nv-menu menuicon" title="User Preferences" (onClick)="userInfoPanel.toggle($event)"></lib-link>
</div>

<lib-overlay [styleClass]="'headerUserInfoPanel ' + theme" [showCloseIcon]="false" #userInfoPanel
  (onHide)="onOverlayHide()">
  <div *ngFor="let item of userInfoItems" class="userInfoItems">
    <div tabindex="0" class="userInfoItem" [ngClass]="item.styleClass"
      (click)="item.command({ originalEvent: $event, item: item })"
      (keyup.enter)="item.command({ originalEvent: $event, item: item })">
      <span [ngClass]="item.icon"></span> {{ item.label }}
      <div *ngIf="item.items" class="themePanel">
        <lib-button (click)="subitem.command({ originalEvent: $event, item: subitem })"
          *ngFor="let subitem of item.items" [styleClass]="subitem.styleClass" [label]="subitem.label">
        </lib-button>
      </div>
    </div>
  </div>
</lib-overlay>

<lib-dialog header="Upload Profile Image" [(visible)]="showUploadImage" [width]="750"
  [contentStyle]="{ overflow: 'auto', 'max-height': '750px' }" positionTop="120" [hideError]="false">
  <div class="p-grid">
    <div class="p-col-12">
      <lib-image-cropper (onUpload)="onUploadImage($event)" previewStyle="previewProfileupload" accept="image/png">
      </lib-image-cropper>
    </div>
  </div>
</lib-dialog>