import { Injectable } from "@angular/core";
import {
  PebtConstants,
  OutData,
  OutRecordsListData,
  PagingData,
  Status
} from "@pebt/common";
import { ApiService } from "@pebt/http";
import { StorageService } from "@pebt/storage";

import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import {

  AddressStateRecordData,  

} from "../model";
import { RefmPipe } from "../pipes/refm-filler.pipe";
import { AddressData } from "@shared/model/address-normalize/address-data.model";

import { GetUserData } from "@shared/model/common/get-user-data.model";

import { FormNameData } from "@shared/model/common/form-name-data.model";

import { StatusOut } from "@shared/model/status/status-out.model";

import { ScreenApplicationInData } from "@shared/model/common/screen-application-in-data.model";
import { LocationStrategy } from "@angular/common";



@Injectable()
export class CommonService {

  // Inject LocationStrategy Service into your component
  constructor(
    private apiService: ApiService,
    private refmPipe: RefmPipe,
    private storageService: StorageService,
    private locationStrategy: LocationStrategy
  ) {

  }

  public appData = {
    userID: "",
    applicationID: ""
  }

 

  private tickerUrl = "Utility/GetTicketTimout";
  public bodyContentHeight = 0

 
  getBodyHeight() {
    return window.innerHeight - (132 + 83);
  }

  deviceWidthLessThan(width) {
    if (window.innerWidth < width) {
      return true;
    }
    return false;
  }


  isMobileDevice() {
    if (window.innerWidth < 768) {
      return true;
    }
    return false;
  }

  isLargeDevice() {
    if (window.innerWidth >= 1680) {
      return true;
    }
    return false;
  }

  isMediumDevice() {
    if (window.innerWidth >= 1366 && window.innerWidth < 1680) {
      return true;
    }
    return false;
  }


  isSmallDevice() {
    if (window.innerWidth >= 768 && window.innerWidth < 1366) {
      return true;
    }
    return false;
  }

  getWindowWidth() {
    return window.innerWidth;
  }

  getApplicationId() {
    return this.storageService.getItem("applicationId");
  }

  GetTicketTimout(): Observable<OutData<any>> {
    return this.apiService.Post({}, this.tickerUrl);
  }

  logOut(): Observable<OutData<Status>> {
    return this.apiService.Post({}, "Account/LogOut");
  }

  getStandardAddress(
    input: AddressData
  ): Observable<OutRecordsListData<AddressData[]>> {
    return this.apiService.Post(
      input,
      "Account/GetStandardizedAddress"
    );
  }

  convertGrouping(values: any[], gropuBy: string): any {
    let groupObj = values.reduce((prv, cur) => {

      if (!prv[cur[gropuBy]]) {
        prv[cur[gropuBy]] = [cur];
      } else {
        prv[cur[gropuBy]].push(cur);
      }

      return prv;
    }, {});
    return groupObj;
  }

  private defaultValues = [
    PebtConstants.LOW_DATE,
    PebtConstants.LOW_DATE_TIME,
    PebtConstants.NULL_INT,
    PebtConstants.NULL_LONG,
    PebtConstants.HIGH_DATE,
    PebtConstants.HIGH_DATE_TIME,
    null, 0
  ];

  checkIsEmpty(value) {
    if (!value) {
      return "";
    }

    if (this.defaultValues.indexOf(value) === -1) {
      return value;
    } else {
      return "";
    }
  }

  checkIsAllNotEmpty(valueList, data, check?) {
    let returnValue = false;
    if (check) {

    }
    valueList.forEach(element => {
      if (check) { console.log(data[element]) }
      if (this.checkIsEmpty(data[element]) !== "") {
        returnValue = true;
      }
    });
    return returnValue;
  }


  

  downloadFile(
    input: any,
    reportName: string,
    fileType: string,
    subSystem: string = "easeofuse",
    screen?: string
  ) {
    const fileName = reportName.replace(/\s/g, "_");
    input.reportName = reportName;

    input.dataUrl = reportName;
    return this.apiService
      .PostFileDownload(
        input,
        reportName,
        "",
        "",
        subSystem + "-api/api",
        screen
      )

      .pipe(
        map((result: any) => {
          if (result && result.body) {
            const anchorElement = document.createElement("a");
            anchorElement.href = window.URL.createObjectURL(result.body);
            anchorElement.download = result.headers.get("X-File-Name");
            anchorElement.style.display = "none";
            document.body.appendChild(anchorElement);
            anchorElement.click();
          } else {
            //this.showDownLoadFailMessage();
            return result;
          }
        })
      );
  }

  GetFormPdfDocuments(input: FormNameData): Observable<any> {
    return this.apiService.Post(input, "Document/GetFormPdfDocument");
  }

  

  scrollToError(): void {
    setTimeout(() => {
      const element = document.querySelector('.field-error');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  }

  scrollToSection(element): void {
    setTimeout(() => {

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  }

  
  SaveLanguage(inputData: any): Observable<any> {
    return this.apiService.Post(inputData, "Account/SaveApplicationLanguage");
  }
 
 
  SaveApplicationLanguage() {
    if (this.storageService.getItem("worker") != null && this.storageService.getItem("worker")) {
      // let lang = this.storageService.getItem("Language");
      // lang = (lang == "")?"EN":lang;
      let input = {
        UserID: this.storageService.getItem("worker"),
        LanguageCode: this.storageService.getItem("Language")
      }
      this.SaveLanguage(input).subscribe(() => {

      })
    }
  }


  // Define a function to handle back button and use anywhere
  preventBackButton() {
    
    history.pushState(null, null, location.href);
    
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
      
    })
  }

  resetHistory() {
    history.replaceState(null, null, location.href);
    history.pushState(null, null, location.href);
   
    this.locationStrategy.onPopState(() => {     
      if (history.state == null) {
        history.pushState(null, null, location.href);       
      }

    })
  }

  getFieldError(formControls) {
    Object.keys(formControls).forEach((key) => {
      if (formControls[key].status == "INVALID") {
        console.log(key);
      }
    });
  }

  changeLang(lang) {
    let languageElem: any = document.getElementsByClassName('goog-te-combo');
    lang = (lang == "") ? "en" : lang;

    this.storageService.setItem("Language", lang);
    this.setSelectedValue(languageElem[0], lang);

  }



  triggerHtmlEvent(element, eventName) {
    let event;
    if (document.createEvent) {
      event = document.createEvent('HTMLEvents');
      event.initEvent(eventName, true, true);
      element.dispatchEvent(event);
    }

  }

  setSelectedValue(selectObj, valueToSet) {
    if (!selectObj) return;
    for (let i = 0; i < selectObj.options.length; i++) {
      //if (selectObj.options[i].text == valueToSet) {
      if (selectObj.options[i].value == valueToSet) {
        selectObj.options[i].selected = true;
        this.triggerHtmlEvent(selectObj, 'change')
        return;
      }
    }
  }

  getRefmOption(key) {
    const fieldKey = "APPL_" + key;
    const rscl = JSON.parse(this.storageService.getItem("RSCL"));
    const item = rscl[fieldKey];
    const refmkey = item['TableID'] + item['TableSubID'];
    const store = JSON.parse(this.storageService.getItem("STORE"));
    const lang = this.storageService.getItem('Language')
    return store.REFM[lang.toUpperCase()][refmkey];

  }

}
