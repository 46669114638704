<div class="p-col-12 errorContainer" *ngIf="false">
  <span *ngIf="messageData.length == 0"> <i class="nv-check"></i> Done </span>
  <span *ngIf="fieldErrorInfo">
    {{ fieldErrorInfo.field | relatedField }}
  </span>
  <div *ngIf="!fieldErrorInfo" aria-live="polite" aria-atomic="true">
    <span *ngIf="messageData.length > 0 && messageData[0].relatedField"
      >{{ messageData[0].relatedField | relatedField }}
      :
    </span>
    <span
      *ngIf="
        messageData.length > 0 &&
        messageData[0].title &&
        (!messageData[0].Description || messageData[0].Description == '')
      "
      >{{ messageData[0].title }}
    </span>
    <span *ngIf="messageData.length > 0"
      > <i class="plsWaitIcon pi pi-refresh"></i> {{ messageData[0].Description }}
    </span>
  </div>
  <!-- <span
    class="ViewError cursorPointer"
    *ngIf="getIsShowMoreError()"
    (click)="fnShowErrorPanel($event)"
    >View Error Details</span
  > -->
  <!-- <span
    (click)="fnCloseErrorHeader($event)"
    class="pull-right fa fa-times cursorPointer"
  ></span> *ngIf="messageData && messageData.length > 0" -->
</div>
<div *ngIf="isInProgress" class="layoutBlocker">
<div class="statusLayer" >
  <div class="pleaseWaitMsg">
    <i class="loader " style="font-size: 3em;"></i>
    <br />
    Please wait...
  </div>
</div>
</div>