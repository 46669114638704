import { Pipe, PipeTransform } from "@angular/core";
import { PebtConstants } from "@pebt/common";
import { UtilityService } from "@pebt/utility";
import { RefmPipe } from "./refm-filler.pipe";

@Pipe({ name: "addressPipe", pure: false })
export class AddressPipe implements PipeTransform {
  constructor(private refmPipe:RefmPipe) { }
  transform(
    addressRecord: { [key: string]: string | number },
    display?: string,
    dataMember?: { [key: string]: string },
  ): string {
    let formatedAddress = "";
    if (dataMember) {
      const defaultphoneObj = {
        line1Addr: "line1Addr",
        line2Addr: "line2Addr",
        cityName: "cityName",
        stateCode: "stateCode",
        postCode: "postCode",
        countyName: "countyName",
        countryCode: "countryCode"
      };

      UtilityService.copyValues(defaultphoneObj, dataMember);

      const record: { [key: string]: string | number } = {};

      for (const key in defaultphoneObj) {
        if (addressRecord.hasOwnProperty(defaultphoneObj[key])) {
          record[key] = addressRecord[defaultphoneObj[key]];
        } else {
          record[key] = "";
        }
      }
      addressRecord = record;
    }

    if (addressRecord && display === "block") {
      if (addressRecord.Line1Addr) {
        formatedAddress += addressRecord.Line1Addr;
      }
      if (addressRecord.Line2Addr) {
        // formatedAddress += "<br>" + addressRecord.Line2Addr;
        formatedAddress += "," + addressRecord.Line2Addr;
      }
      if (addressRecord.CityName) {
        formatedAddress += "<br>" + addressRecord.CityName;
      }
      if (addressRecord.StateName) {
        formatedAddress += ", " + addressRecord.StateName;
      }
      if (addressRecord.ZipAddr) {
        formatedAddress +=
          ", <span class='addrZip'>" +
          this.formatZip(addressRecord.ZipAddr) +
          "</span>";
      }
      if (addressRecord.CountyName) {
        formatedAddress += "<br>" + addressRecord.CountyName;
      }
      if (addressRecord.CountryCode) {
        formatedAddress += "<br>" + addressRecord.CountryCode;
      }
    } else if (addressRecord) {
      if (addressRecord.Line1Addr) {
        formatedAddress += addressRecord.Line1Addr;
      }
      if (addressRecord.Line2Addr) {
        formatedAddress +=
          formatedAddress === ""
            ? addressRecord.Line2Addr
            : ", " + addressRecord.Line2Addr;
      }
      if (addressRecord.CityName) {
        formatedAddress +=
          formatedAddress === ""
            ? addressRecord.CityName
            : ", " + addressRecord.CityName;
      }
      if (addressRecord.StateName) {
        formatedAddress +=
          formatedAddress === ""
            ? addressRecord.StateName
            : ", " + addressRecord.StateName;
      }   

      

      if (addressRecord.ZipAddr) {
        const ZipAddr = this.formatZip(addressRecord.ZipAddr);
        formatedAddress += formatedAddress === "" ? ZipAddr : ", " + ZipAddr;
      }

      if (addressRecord.CountyName) {
        formatedAddress +=
          formatedAddress === ""
            ? addressRecord.CountyName
            : ", " + addressRecord.CountyName;
      }
    }

    return formatedAddress;
  }

  formatZip(postCode: string | number) {
    const zipCode = postCode.toString();
    const formatZipCode =
      zipCode.length === PebtConstants.NUMBER_9
        ? zipCode.slice(0, PebtConstants.NUMBER_5) +
        "-" +
        zipCode.slice(PebtConstants.NUMBER_5, zipCode.length)
        : zipCode;

    return formatZipCode;
  }
}
