import { Component, Input, HostListener, Output, EventEmitter } from "@angular/core";
import { NavigationEnd } from "@angular/router";
import { ScreenComponent, IScreenInfo } from "@pebt/uibase";
import { AppConstants } from "@shared/constants/app-constants";
import { CommonService } from "@shared/services/common.service";
import { TranslatorService } from "@shared/services/translator.service";

import { interval, Subscription } from "rxjs";
import { appConfig } from "src/config";
@Component({
  selector: "app-page-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent extends ScreenComponent {
  @Input() isScreen: boolean = false;
  @Input() loginScreen: boolean = false;
  @Input() isFillingPage: boolean = false;
  @Input() maintenanceScreen: boolean = false;

  @Output() logOffClicked:EventEmitter<any> = new EventEmitter<any>();

  screenInfo: IScreenInfo
  public isautherized = false;

  selectedLang = "Español";

  constructor(private commonService: CommonService,
    private appConstants: AppConstants,
    private translatorService: TranslatorService) {
    super();
    this.fullHeaderPageList = [
      this.appConstants.URL.login,
      this.appConstants.URL.adminLogin,
      this.appConstants.URL.resetUser,
      this.appConstants.URL.resetPassword,
      this.appConstants.URL.changePassword,
      this.appConstants.URL.dashboard,
      this.appConstants.URL.adminDashboard
    ]
  }


  @Input() activeUser: boolean = false;
  showLogout: boolean = false;
  isAdmin: boolean = false;
  loginUser: string = "";
  onInitialize() {
   // this.isAdmin = appConfig.isAdmin;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // if (this.fullHeaderPageList.indexOf(event.url) === -1) {
        //   this.isFullHeader = false;
        // }
        // else {
        //   this.isFullHeader = true;
        // }
        this.isFullHeader = false;
        this.fullHeaderPageList.forEach(element => {
          if(event.url.indexOf(element) > -1){
            this.isFullHeader = true;
          }
        });

      }
    });
  }
  isFullHeader: boolean = true;

  fullHeaderPageList = []
  onViewReady(): void {
    this.loginUser = this.storageService.getItem("userName");
    this.isFullHeader= false;
    this.fullHeaderPageList.forEach(element => {
      if(this.router.url.indexOf(element) > -1){
        this.isFullHeader = true;
      }
    });

    // if (this.fullHeaderPageList.indexOf(this.router.url) === -1) {
    //   this.isFullHeader = false;
    // }
  }

  onDestroy(): void { }
  gotoHome() {
    this.doNavigate(this.appConstants.URL.home)
  }

  gotoLink() {


    let url = this.appConstants.URL.search;

    if (this.activeUser) {
      // if (this.router.url == this.appConstants.URL.dashboard || this.router.url == this.appConstants.URL.adminDashboard) {
      //   url = this.appConstants.URL.home;
      // }
      // else {
        // if (appConfig.isAdmin)
        //   url = this.appConstants.URL.adminDashboard;
        // else
          url = this.appConstants.URL.search;
     // }
    }

    this.doNavigate(url)

  }

  onLogOff(event) {
    this.logOffClicked.emit();
    // let x = this.storageService.getItem("access_token")
    // this.commonService.logOut()
    //   .subscribe((result: any) => {
    //     result.validateResponse(
    //       () => {
    //         this.storageService.removeItem("access_token");
    //         this.doNavigate(this.appConstants.URL.login);
            
    //       },
    //       () => { }
    //     );
    //   });
  }
  userSetting(event) {

    this.showLogout = true
    event.stopPropagation();
    event.preventDefault();
  }

  showLanguage(event) {

    let panelYPos = event.pageY + 30;
    let element = document.getElementById("header-innerpage");

    if (element && element.getBoundingClientRect()) {
      let elementDetails = element.getBoundingClientRect();
      panelYPos = elementDetails.height - 1;
    }


    if (this.commonService.isLargeDevice()) {
      this.translatorService.showTranslatorPanel(200, panelYPos);
    }
    else {
      this.translatorService.showTranslatorPanel(0, panelYPos);
    }
  }


  @HostListener('window:click', ['$event'])
  clickout(event) {
    this.showLogout = false;
  }
}
