import { Injectable } from "@angular/core";

export const blueTheme = {
  "primary-header-bg": "#12283D",
  "primary-header-border": "#152A4C",
  "primary-menu-bg": "#19325C",
  "primary-menu-border": "#6C7C96",
  "primary-menu-active-color": "#19325C",
  "primary-menu-color": "#98ACCA",
  "primary-menu-active-border-top": "#FFFFFF",
  "secondary-menu-active-border": "#905A6A",
  "secondary-menu-active-bg": "#895061",
  "button-bg": "#3373CB",
  "button-hover-bg": "#2E67B6",
  "filter-btn-bg": "#D3E6FF",
  "filter-btn-hover-bg": "#EEF5FF",
  "filter-btn-border": "#A5C4EC",
  "select-btn-active-bg": "#FFE4DC",
  "select-btn-active-hover-bg": "#FFEEE9",
  "split-btn-bg": "#00A6E4",
  "split-btn-border": "#74CEF0",
  "active-border": "#38AEE9",
  "panel-header-bg": "#475971",
  "panel-header-border": "#2B3947",
  "accordion-header-active-bg": "#CEE2F7",
  "accordion-header-active-border": "#BDD5EC",
  "left-menu-header-bg": "#475971",
  "left-menu-header-border": "#2E3A4B",
  "left-menu-text-color": "#849DB6",
  "left-menu-item-primary-bg": "#323E4E",
  "left-menu-item-primary-border": "#2A3647",
  "left-menu-item-secondary-bg": "#323E4E",
  "left-menu-item-secondary-border": "#2A3647",
  "left-menu-active-bg": "#475971",
  "left-menu-active-color": "#FFF",
  "grid-header-bg": "#fff",//#4C87B9",
  "grid-header-border": "#B7C9DC",
  "grid-alternate-row-bg": "#EEEEEE",
  "grid-cell-border-color": "#fff",//#EAEAEA",
  "grid-selected-row-bg": "#e2f1ff",
  "grid-text-color": "#333",
  "grid-row-hover": "#DDFFCD",
  "grid-paginator-active-bg": "#215295",
  "grid-filter-icon-bg": "#215295",
  "grid-filter-refresh-icon-bg": "#ff8a6f",
  "check-selected-color": "#3F90EA",
  "associated-menu-header-bg": "#3A2230",
  "associated-menu-item-bg": "#895061",
  "associated-menu-border-top": "#3A2230",
  "tab-active-border": "#3877CD",
  "associated-menu-item-text": "#ffffff",
  "profile-bg": "#1D457C",
  "login-username-color": "#FFF",
  "login-page-bg": "#dbe9ed",
  "editor-tool-bg": "#E6F7FF",
  "participant-baner-header-color": "#1785AE",
  "participant-baner-header-icon": "#399BC0",
  "participant-baner-header-bg": "#D5EAFF",
  "participant-baner-header-border": "#A4D5E8",
  "stepper-active-color": "#303946",
  "stepper-active-circle-bg": "#46B7DE",
  "stepper-active-circle-border": "#2F9FE0",
  "alert-notify-color": "#24F5C8",
  "select-btn-bg": "#E9F2FD",
  "select-btn-border": "#B6C7DF",
  "select-btn-color": "#7A9AC6",
  "select-btn-active-color": "#C76649",

  "textbox-hover-border": "#36C3F8",
  "textbox-focused-border": "#00A6E4"
};

export const pinkTheme = {
  "primary-header-bg": "#C488BE",
  "primary-header-border": "#B779B1",
  "primary-menu-bg": "#A15C9A",
  "primary-menu-border": "#A2609C",
  "primary-menu-active-border-top": "#ffffff",
  "primary-menu-active-color": "#C13AB4",
  "primary-menu-color": "#ffffff",
  "secondary-menu-active-border": "#B57FB0",
  "secondary-menu-active-bg": "#AC47A2",
  "button-bg": "#5C9976",
  "button-hover-bg": "#53896A",
  "filter-btn-bg": "#E6FCF0",
  "filter-btn-hover-bg": "#F2FEF7",
  "filter-btn-border": "#5B8F71",
  "select-btn-active-bg": "#A15C9A",
  "select-btn-active-hover-bg": "#AB6DA4",
  "split-btn-bg": "#AC47A2",
  "split-btn-border": "#E3C1E0",
  "active-border": "#AC47A2",
  "panel-header-bg": "#FFECFD",
  "panel-header-border": "#EFEEFB",
  "accordion-header-active-bg": "#E8D7E6",
  "accordion-header-active-border": "#E1CBDE",
  "left-menu-header-bg": "#26273B",
  "left-menu-header-border": "#ad67b8",
  "left-menu-text-color": "#fff",
  "left-menu-item-primary-bg": "#494F69",
  "left-menu-item-primary-border": "#3F4259",
  "left-menu-item-secondary-bg": "#3C3F55",
  "left-menu-item-secondary-border": "#2C2B3D",
  "left-menu-active-bg": "#945d9d ",
  "left-menu-active-color": "#fff",
  "tab-active-border": "#AC47A2",
  "grid-header-bg": "#7A7697",
  "grid-header-border": "#9C99B4",
  "grid-cell-border-color": "#E5E4ED",
  "grid-selected-row-bg": "#C6EDD7",
  "grid-text-color": "#474D55",
  "grid-row-hover": "#F3EEE4",
  "grid-paginator-active-bg": "#C488BE",
  "grid-filter-icon-bg": "#C488BE",
  "grid-filter-refresh-icon-bg": "#ff8a6f",
  "check-selected-color": "#53896A",
  "associated-menu-header-bg": "#362132",
  "associated-menu-item-bg": "#955A8B",
  "associated-menu-item-text": "#ffffff",
  "associated-menu-border-top": "#362132",
  "profile-bg": "#AE79A9",
  "login-username-color": "#ffffff",
  "login-page-bg": "#dbe2f7",
  "editor-tool-bg": "#E8D7E6",
  "participant-baner-header-color": "#33773f",
  "participant-baner-header-icon": "#65926d",

  "participant-baner-header-bg": "#f3eee4",
  "participant-baner-header-border": "#e9dec3",
  "stepper-active-color": "#303946",
  "stepper-active-circle-bg": "#7EBE99",
  "stepper-active-circle-border": "#5C9976",
  "alert-notify-color": "#98FF6A",
  "select-btn-bg": "#A15C9A",
  "select-btn-border": "#DCDCDC",
  "select-btn-color": "#6A6A6A",
  "select-btn-active-color": "#FFF",
  "textbox-hover-border": "#E7B9E2",
  "textbox-focused-border": "#BA54B0"
};
export const greenTheme = {
  "primary-header-bg": "#88c66c",
  "primary-header-border": "#77ab6f",
  "primary-menu-bg": "#65926d",
  "primary-menu-border": "#c9d8cc",
  "primary-menu-active-color": "#668a68",
  "primary-menu-active-border-top": "#ffffff",
  "primary-menu-color": "#ffffff",
  "primary-menu-active-bg": "#ffffff",
  "secondary-menu-active-border": "#8dae93",
  "secondary-menu-active-bg": "#65926d",
  "button-bg": "#88c66c",
  "button-hover-bg": "#7ab261",
  "filter-btn-bg": "#e6ddf8",
  "filter-btn-border": "#aa9acb",
  "filter-btn-hover-bg": "#efe8ff",
  "select-btn-active-bg": "#7ab261",
  "select-btn-active-hover-bg": "#7ebb63",
  "split-btn-bg": "#77b55c",
  "split-btn-border": "#d0e5c6",
  "active-border": "#6d9d57",
  "panel-header-bg": "#f2f6f0",
  "panel-header-border": "#e6f5e0",
  "tab-active-border": "#87C56A",
  "accordion-header-active-bg": "#d7eccd",
  "accordion-header-active-border": "#b6d3aa",
  "left-menu-header-bg": "#202a38",
  "left-menu-header-border": "#1ca728",
  "left-menu-text-color": "#fff",
  "left-menu-item-primary-bg": "#41545f",
  "left-menu-item-primary-border": "#394953",
  "left-menu-item-secondary-bg": "#34444c",
  "left-menu-item-secondary-border": "#253136",
  "left-menu-active-bg": "#1aab25",
  "left-menu-active-color": "#fff",
  "grid-cell-border-color": "#cce7fb",
  "grid-header-bg": "#6c8386",
  "grid-header-border": "#739cb1",
  "grid-selected-row-bg": "#d8cdef",
  "grid-text-color": "#474D55",
  "grid-row-hover": "#F3EEE4",
  "grid-paginator-active-bg": "#88C66C",
  "grid-filter-icon-bg": "#88c66c",
  "grid-filter-refresh-icon-bg": "#ff8a6f",
  "check-selected-color": "#338e43",
  "associated-menu-header-bg": "#446644",
  "associated-menu-item-bg": "#7DA87D",
  "associated-menu-item-text": "#ffffff",
  "associated-menu-border-top": "#446644",
  "profile-bg": "#6A9B54",
  "login-username-color": "#ffffff",
  "login-page-bg": "#dbe9ed",
  "editor-tool-bg": "#DCEFD3",
  "participant-baner-header-color": "#33773f",
  "participant-baner-header-icon": "#65926d",
  "participant-baner-header-bg": "#f3eee4",
  "participant-baner-header-border": "#e9dec3",
  "stepper-active-color": "#8d6bd5",
  "stepper-active-circle-bg": "#bca7ea",
  "stepper-active-circle-border": "#8d6bd5",
  "alert-notify-color": "#FF3CEC",
  "select-btn-bg": "#dadada",
  "select-btn-border": "#d4d4d4",
  "select-btn-color": "#676767",
  "select-btn-active-color": "#fff",
  "textbox-border": "#c9c6cc",
  "textbox-hover-border": "#c5e8b4",
  "textbox-focused-border": "#68c640"
};

@Injectable({
  providedIn: "root"
})
export class ThemingService {
  constructor() {}
  themes = {
    green: greenTheme,
    pink: pinkTheme,
    blue: blueTheme
  };

  // setBlueTheme() {
  //   this.setTheme(blueTheme);
  // }

  // setPinkTheme() {
  //   this.setTheme(pinkTheme);
  // }

  // setYellowTheme() {
  //   this.setTheme(greenTheme);
  // }

  setTheme(themeColor: string) {
    const theme = this.themes[themeColor];
    Object.keys(theme).forEach((key) =>
      document.documentElement.style.setProperty(`--${key}`, theme[key])
    );
  }
}
