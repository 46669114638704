export const environment = {
  production: true,
  applicationName: "Pebt",
  appSettingsId: null,
  appVersion: 1,
  appLastBuild: null,
  baseUrl: "Pebt",
  defaultUrl: "WRKL",
  environment: "P", // D- Development, T-Testng ,S-Training , P-Production
  isProduction: true,
  checkAccess: true,
  allowedIdleTimeMins: 15, // Mins
  downloadLimit: 100,
  defaultRefmValue: {
    label: "-SELECT-",
    value: ""
  },
  logConfig: {
    logBaseUrl: "https://pebt.mynjhelps.gov/PebtApi/api/",
    logApiMethod: "childsupport-api/api/anonymous/log/error"
  },
  apiConfig: {
    apiEndPoint: "https://pebt.mynjhelps.gov/PebtApi/api/",
    serviceRetryCount: 3,
    serviceRetryDelay: 500, // ms
    service2Cache: [],
    sessionTimeOut: 15, // Mins
    sessionWarningTime: 1
  },  
  startTime: "6:00 AM",
  endTime: "6:00 PM",
  jsonEncode: true,
  contact : "http://www.nj.gov/humanservices/njsnap/emergency/pebt",
  siteKey: "6LeUc1kkAAAAAFE4oTgrad-_-wgDMa8ttb-x274k",
  analyticsKey: "G-K09Q7SJ2K9"
};
