import { Pipe, PipeTransform } from "@angular/core";
import { PebtConstants } from "@pebt/common";

@Pipe({ name: "phone" })
export class PhonePipe implements PipeTransform {
  transform(phoneNumber: any): string {
    if (phoneNumber === 0 || phoneNumber === PebtConstants.NULL_LONG) {
      return "";
    } else if (phoneNumber > 0 && phoneNumber.toString().length === 10) {
      const type = phoneNumber[10] ? phoneNumber[10] : "";
      const cleaned = ("" + phoneNumber).replace(/\D/g, "");
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match && type) {
        return (
          "(" + match[1] + ") " + match[2] + "-" + match[3] + "(" + type + ")"
        );
      } else {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
    }

    return phoneNumber;
  }
}
