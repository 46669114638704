import { Component, Input } from "@angular/core";
import { FormGroupSection } from "@pebt/common";
import { IScreenInfo, ScreenFormComponent } from "@pebt/uibase";

import { CommonService } from "@shared/services/common.service";
import { appConfig } from "src/config";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent extends ScreenFormComponent {
  onFormValueChange(data: any): void {
    //throw new Error("Method not implemented.");
  }
  screenInfo: IScreenInfo;
  constructor(private commonService: CommonService) {
    super();
  }
  @Input() innerFooter: boolean = false;
  @Input() showFirstFooter: boolean = false;
  showFeedback: boolean = false;
  contact : string = appConfig.contact;
  gotoContact() {
    this.doNavigate("/contactus");
  }
  onInitialize(): void {
    this.rootFormGroup = this.buildFormGroup(
      {
        FeedbackCode: [""],
        DescriptionNoteText: [""]
      },
      FormGroupSection.bodySection)
  };

  onViewReady(): void { }
  onDestroy(): void { }

  onOkClick() {
   

  }

  onDialogHide() {
    this.showFeedback = false;
  }

}
