<lib-dialog
  styleClass="confirmDialog"
  width="500"
  [(visible)]="showDialog"
  (onShow)="onDialogShow()"
  (onHide)="onDialogHide()"
  [header]="header"
  [hideError]="false"
  [modal]="true"
>
  <div class="" role="alert" aria-atomic="true">
    <div class="confirmDialog-content p-grid ">
      <div class="p-col-fixed" style="width:25px">
        <i class="pi pi-info-circle confirmdialog-icon pull-left"></i>
      </div>
      <div class="p-col">
        <lib-label
          *ngIf="!messageTemplate"
          type="block"
          class="confirmdialog-text"
          [text]="message"
        ></lib-label>
        <ng-container *ngTemplateOutlet="messageTemplate"></ng-container>
      </div>
    </div>
  </div>
  <div cFooter>
    <div class="pull-right">
      <lib-button
        #yesBtn
        class="btn-gap"
        [label]="yesButtonLabel"
        (click)="yesClicked()"
      ></lib-button>
      <lib-button
        *ngIf="showNo"
        [label]="noButtonLabel"
        (click)="noClicked()"
      ></lib-button>
    </div>
  </div>
</lib-dialog>
