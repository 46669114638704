export class AdvancedNavigationRecordData {
  caseIdRecordDataList: CaseIdRecordData[];
  caseScreenCodeList: ScreenCodeRecordData[];
  docketIdcRecordDataList: DocketIdcRecordData[];
  docketScreenCodeList: ScreenCodeRecordData[];
  personIdRecordDataList: PersonIdRecordData[];
  personScreenCodeList: ScreenCodeRecordData[];
}

export class CaseIdRecordData {
  caseId: number;
}

export class ScreenCodeRecordData {
  screenCode: string = "";
}

export class DocketIdcRecordData {
  docketIdc: string;
}
export class PersonIdRecordData {
  personId: number;
}
