import { BaseModel, PebtConstants } from "@pebt/common";

export class AddNavigationDetailInData extends BaseModel {
  caseId: number = PebtConstants.NULL_LONG;
  docketIdc: string = "";
  personId: number = PebtConstants.NULL_LONG;
  screenCode: string = "";
  screenFunctionCode: string = "";
  searchKeyJson: string = "";
  signedonOfficeCode: string = "";
  signedonUserName: string = "";
}
