export enum ButtonType {
  add = 1,
  delete,
  refresh,
  save,
  close,
  clear,
  custom,
  edit
}
export interface CustomButtonInfo {
  label: string;
  icon: string;
  callback: (event?: Event) => void;
  buttonType?: ButtonType;
}
export interface ButtonConfig {
  buttonType: ButtonType;
  disabled?: boolean;
  customButton?: CustomButtonInfo;
}
