import { BaseModel, PebtConstants } from "@pebt/common";
import { GeoLocationData } from "./geo-location-data.model";

export class AddressData extends BaseModel {
    SeqID: string = "";
    TypeAddressCode: string = "";
    Line1Addr: string = "";
    Line2Addr: string = "";
    CityName: string = "";
    StateAddr: string = "";
    ZipAddr: string = "";
    CityLimitIndc: string = "";
    CountryAddr: string = "";
    StateFipsCode: string = "";
    CountyAddr: string = "";
    CountyName: string = "";
    CountyIdno: number = PebtConstants.NULL_INT;
    NormalizeCode: string = "";
    TransactionEventSeqNumb: number = 0;
    GeoLocation: GeoLocationData = new GeoLocationData();
    StateName: string = "";
    CityAddr: string = "";
    
}
