import { Directive, ElementRef, HostListener, Input, Renderer2,  TemplateRef,  ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[dynamics]",
  // providers:[
  //   TemplateRef
  // ]
})
export class DynamicDirective {
  globalInstance: any;
  value: string = "";
  constructor(private renderer: Renderer2,
    //private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    element: ElementRef) {
    
    // element.nativeElement.addEventListener('DOMSubtreeModified', this.myFunction);

    // this.globalInstance = this.renderer.listen(element.nativeElement, 'DOMSubtreeModified', (e) => {
    //   this.renderer.setStyle(element.nativeElement, 'display', 'none');
    //   setTimeout(() => {
    //     this.renderer.setStyle(element.nativeElement, 'display', 'block');
    //   }, 100);

    // });



    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation: any) => {
        console.log(mutation)
        if (mutation.type == "attributes") {
          // if (mutation.attributeName == "ng-reflect-text") {
          //   mutation.target.innerHTML = mutation.target.getAttribute("ng-reflect-text")
          // }
          // else 

          if (mutation.attributeName == "title") {
            // mutation.target.innerHTML = mutation.target.title;

            if (mutation.target.lastChild && mutation.target.lastChild.nodeName == 'FONT') {
              //let el = mutation.target.classList;
             // el.add("hidden");
             mutation.target.lastChild.lastChild.innerText = mutation.target.title; 
             this.viewContainer.clear();
              
              setTimeout(() => {
                //el.remove("hidden");
                this.viewContainer.createEmbeddedView(mutation.target);
              }, 100);
            }


          }
        }

      });
    });

    observer.observe(element.nativeElement, {
      attributes: true,
      childList: true,
      characterData: true
    });
  }

  hasView:boolean = false;

@Input() set appUnless(condition: boolean) {
  // if (!condition && !this.hasView) {
  //   this.viewContainer.createEmbeddedView(this.templateRef);
  //   this.hasView = true;
  // } else if (condition && this.hasView) {
  //   this.viewContainer.clear();
  //   this.hasView = false;
  // }
}
  @HostListener("change", ["$event"]) onchange(event: MouseEvent) {
    
  }

  myFunction(e) {
    // console.log(this.element.nativeElement.innerHTML);

    
    let element = e.target.innerHTML;
    console.log(e);
    // e.target.innerHTML = "";
    // setTimeout(() => {
    //   e.target.innerHTML = element;
    //     }, 100);
  }


}
