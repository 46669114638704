<lib-panel class="pageContainerPanel" [isFooter]="isLongForm" [ngClass]="{ longFormContainer: isLongForm }">
  <div cHeader class="p-grid">
    <div [ngClass]="headerTextLayoutClass" class="no-pad">
      {{header}}
    </div>
    <div [ngClass]="headerButtonLayoutClass" class="text-right no-pad">
      <ng-container *ngFor="let btn of buttonConfig; let i = index">
        <lib-button *ngIf="btn.buttonType != 7" [label]="buttonInfo[btn.buttonType].label" [ariaLabel]="
            buttonInfo[btn.buttonType].icon === 'fa fa-close' &&
            !buttonInfo[btn.buttonType].label
              ? 'Close'
              : null
          " [icon]="buttonInfo[btn.buttonType].icon" (onClick)="onClick($event, btn)"
          [ngClass]="{ 'btn-gap': i != buttonConfig.length - 1 }" [disabled]="btn.disabled"></lib-button>
        <lib-button *ngIf="btn.buttonType == 7" [label]="btn.customButton.label" [icon]="btn.customButton.icon"
          [type]="btn.customButton.buttonType" (onClick)="onClick($event, btn)"
          [ngClass]="{ 'btn-gap': i != buttonConfig.length - 1 }" [disabled]="btn.disabled"></lib-button>
      </ng-container>
    </div>
  </div>
  <div class="pageContainerContent">
    <ng-content></ng-content>
  </div>
  <div cFooter *ngIf="isLongForm">
    <div class="text-right no-pad">
      <ng-container *ngFor="let btn of buttonConfig; let i = index">
        <lib-button *ngIf="btn.buttonType != 7" [label]="buttonInfo[btn.buttonType].label"
          [icon]="buttonInfo[btn.buttonType].icon" (onClick)="onClick($event, btn, true)"
          [ngClass]="{ 'btn-gap': i != buttonConfig.length - 1 }" [disabled]="btn.disabled"></lib-button>
        <lib-button *ngIf="btn.buttonType == 7" [label]="btn.customButton.label" [icon]="btn.customButton.icon"
          [type]="btn.customButton.buttonType" (onClick)="onClick($event, btn, true)"
          [ngClass]="{ 'btn-gap': i != buttonConfig.length - 1 }" [disabled]="btn.disabled"></lib-button>
      </ng-container>
    </div>
  </div>
</lib-panel>