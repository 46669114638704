import { Component, Input, Renderer2 } from "@angular/core";
import { PebtConstants } from "@pebt/common";
import { ViewBaseComponent } from "@pebt/uibase";

@Component({
  selector: "app-status-layer",
  templateUrl: "./status-layer.component.html",
  styleUrls: ["./status-layer.component.scss"]
})
export class StatusLayerComponent extends ViewBaseComponent {
  @Input() show: boolean = false;
  constructor(private renderer: Renderer2) {
    super();
  }

  showLayer() {
    if (this.show) {
      const layer = document.getElementsByClassName("statusLayer");
      if (layer.length === PebtConstants.NUMBER_0) {
        return true;
      }

      return false;
    }

    return false;
  }

  onInitialize(): void {}
  onViewReady(): void {}
  onDestroy(): void {}
}
