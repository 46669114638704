
import { Injectable } from "@angular/core";
import { StorageService } from "@pebt/storage";
import { DataStoreService } from "./shared/services/data-store.service";

@Injectable()
export class AppInitService {
  constructor(private dataStore: DataStoreService, private storageService: StorageService) { }
  /**
   * Initialize Datastore
   * @returns Promise
   */
  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      
      //this.dataStore.loadDataStore().then(() => {
        resolve();
      //});
      
    });
  }
}
