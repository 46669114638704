<div [ngClass]="styleClass">
  <label
    class="label"
    attr.aria-label="{{ labelText }}-{{ valueText }}"
    [ngClass]="labelClass"
  >
    {{ labelText }}
  </label>
  <span
    class="labelValue"
    [ngClass]="valueClass"
    innerHTML="{{ valueFieldText | addressPipe: 'block' }}"
  >
  </span>
</div>
