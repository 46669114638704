import { Directive, HostListener, Input, Renderer2 } from "@angular/core";

@Directive({
  selector: "[tooltip]"
})
export class TooltipDirective {
  @Input("tooltip") tooltipText: string = "";

  private tooltipElement: HTMLDivElement;
  constructor(private renderer: Renderer2) {}
  private ispositioned = false;
  private mouseonTooltip = false;
  @HostListener("mouseenter", ["$event"]) onmouseover(event: MouseEvent) {
    if (!this.tooltipElement) {
      this.tooltipElement = this.renderer.createElement("div");
      this.tooltipElement.innerText = this.tooltipText;
      this.renderer.addClass(this.tooltipElement, "custom-tooltip");
      this.tooltipElement.style.top = 10 + event.clientY + "px";
      this.tooltipElement.style.left = event.clientX + "px";
      this.ispositioned = false;
      this.renderer.listen(this.tooltipElement, "click", (event: Event) => {
        event.stopImmediatePropagation();
      });
      this.renderer.listen(this.tooltipElement, "mouseover", event => {
        this.mouseonTooltip = true;
      });
      this.renderer.listen(this.tooltipElement, "mouseout", event => {
        this.mouseonTooltip = false;
        this.removeTooltip();
      });
      document.body.append(this.tooltipElement);
    }
    if (this.tooltipElement && !this.ispositioned) {
      this.ispositioned = true;

      if (
        this.tooltipElement.clientWidth +
          parseInt(this.tooltipElement.style.left) >
        document.body.clientWidth
      ) {
        this.tooltipElement.style.right = "0";
        this.tooltipElement.style.left = "auto";
      }
    }
  }

  @HostListener("document:click", ["$event"]) onDocumentKeyDown(event) {
    this.removeTooltip();
  }

  removeTooltip() {
    if (this.tooltipElement) {
      this.tooltipElement.remove();
      this.tooltipElement = null;
    }
  }

  @HostListener("mouseout", ["$event"]) onmouseout(event) {
    setTimeout(() => {
      if (this.tooltipElement && !this.mouseonTooltip) {
        this.tooltipElement.remove();
        this.tooltipElement = null;
      }
    }, 1000);
  }
}
