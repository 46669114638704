<div class="errorBaner p-grid no-pad" *ngIf="messageData.length > 0 && !showErrorSideBar && showErrorBaner">
  <div class="p-col no-pad message-container" libClickSelect="true">
    <span [tooltip]="fieldErrorInfo.field" class="messageTitle" *ngIf="fieldErrorInfo">
      {{ fieldErrorInfo.field | relatedField }}
    </span>
    <div role="alert" aria-atomic="true" class="messageTitle" *ngIf="!fieldErrorInfo">
      <span [tooltip]="messageData[0].messages[0]" *ngIf="
          messageData.length > 0 &&
          messageData[0].messages &&
          messageData[0].messages.length > 0
        ">{{ messageData[0].messages[0] }} &nbsp;</span>
      <span [tooltip]="messageData[0].RelatedField"
        *ngIf="messageData.length > 0 && messageData[0].RelatedField">{{ messageData[0].RelatedField | relatedField }}
        :&nbsp;</span>
      <span [tooltip]="messageData[0].title" *ngIf="
          messageData.length > 0 &&
          messageData[0].title &&
          (!messageData[0].Description || messageData[0].Description == '')
        ">{{ messageData[0].title }}
      </span>
      <span class="errorMessageDesc" [title]="messageData[0].Description"
        *ngIf="messageData.length > 0">{{ messageData[0].Description }}
      </span>
    </div>
  </div>
  <div class="p-col-fixed no-pad" [ngClass]="{ moreError: isMoreError }" style="width: 40px">
    <a *ngIf="isMoreFormError()" tabindex="0" aria-label="Click Here to View Error Panel" #moreErrorlink
      class="moreErrorlink" (click)="fnShowErrorPanel()" (keyup.enter)="fnShowErrorPanel()">
      {{
        messageData[0].type == "Alert" && messageData[0].messages.length > 0
          ? "View Details"
          : "View Error Details"
      }}
    </a>
    <lib-link class="closeIcon" title="Click Here to Close Error Panel" (onClick)="hideBaner()" text="X">
    </lib-link>
  </div>
</div>
<lib-side-bar styleClass="errorSidebar" class="withError" [(visible)]="showErrorSideBar" [style]="{ width: '25%' }"
  [modal]="false" position="{{ errorPanelPosition }}" (onHide)="onerrorPanelHide()">
  <div class="errorPanelHeader bold">
    Error Detail
  </div>
  <div class="p-grid ui-fluid inputDropdown-container">
    <div class="p-col-12" [formGroup]="rootFormGroup">
      <lib-select *ngIf="!isAlert" class="hiddenlabel p-col-10 no-pad" placeholder="Search within Messages"
        formControlName="messageSearch" label="Search within Messages" (onSelectionChange)="onMessageSearch($event)"
        [options]="messageOptions" [isNeedDefault]="false" [editable]="true">
      </lib-select>
    </div>
  </div>

  <div class="errorPanelContainer">
    <div class="errorpanelItem p-col-12 float-left" *ngFor="let message of messageData">
      <div class="">
        <div class="errorHeader" [ngClass]="message.type + ' panel'">
          <span class="msgTitle" [ngClass]="{ alertbox: isAlert }">
            <span [ngClass]="{ 'nv-cancel': !isAlert }"></span>
            {{ message.type }}
          </span>
        </div>
        <span class="bold text-dark-blue"> {{ message.Code }} </span>
      </div>
      <div class="messagetextrow">
        <!--<span class="errorDesc">{{ message.title }} </span>-->
        <span class="errorDesc">{{ message.Description }} </span>
      </div>
      <div *ngIf="!showFormErrors" class="p-col-12 no-pad errorInfoPanel">
        <div *ngFor="let message of message.messages" class="alertInfo Item p-col-12 no-pad">
          {{ message }}
        </div>
        <ul class="errorListContainer">
          <li *ngFor="let error of message.formErrrors" class="errorInfoPanelItem p-col-12 no-pad"
            (click)="messageClick($event, error)" tabindex="0" (keyup.enter)="messageClick($event, error)">
            <div>
              <div class="p-col-12 no-pad">
                <span class="bold fieldLabel">Field: </span><span>{{ error.RelatedField }}</span>
              </div>
              <div class="p-col-12 no-pad">
                <span class="bold fieldLabel">Error: </span><span>{{ error.Description }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div [ngClass]="
              !showFormErrors
                ? 'messagetextrow p-col-12 no-pad'
                : 'p-col-12 no-pad messagetextrow border-top'
            ">
        <span *ngIf="message.formErrrors && message.formErrrors.length > 0" [ngClass]="
                !showFormErrors
                  ? 'errordockIcon fa fa-caret-up pull-right text-dark-blue cursorPointer'
                  : 'fa fa-caret-down pull-right text-dark-blue cursorPointer errordockIcon'
              " (click)="
                showFormErrors == true
                  ? (showFormErrors = false)
                  : (showFormErrors = true)
              "></span>
      </div>
    </div>
  </div>

</lib-side-bar>