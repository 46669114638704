import { Pipe, PipeTransform } from "@angular/core";
@Pipe({ name: "namePipe" })
export class NamePipe implements PipeTransform {
  constructor() { }
  transform(nameRecord: { [key: string]: string }): string {
    let formatedName = "";
    if (nameRecord) {
      if (nameRecord.NAME_LAST) {
        formatedName += nameRecord.NAME_LAST;
      } else {
        return formatedName;
      }
      if (nameRecord.NAME_FIRST && nameRecord.NAME_LAST) {
        formatedName += " " + nameRecord.NAME_FIRST;
      }
      if (nameRecord.NAME_MI) {
        formatedName += " " + nameRecord.NAME_MI;
      }
    }

    return formatedName;
  }
}
