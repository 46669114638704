import { Pipe, PipeTransform } from "@angular/core";
import { PebtConstants } from "@pebt/common";

@Pipe({ name: "dateAlone" })
export class DateTimeZonePipe implements PipeTransform {
  transform(value: any): string {
    if (
      !value ||
      value === PebtConstants.LOW_DATE_TIME ||
      value === PebtConstants.HIGH_DATE_TIME
    ) {
      return "";
    }
    return value.split("T")[0];
    
  }
}
