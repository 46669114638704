import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroupSection, OutData, OutRecordsListData } from "@pebt/common";
import { IScreenInfo, ScreenFormComponent } from "@pebt/uibase";
import { AddressData } from "@shared/model/address-normalize/address-data.model";
import { AddressInfo } from "@shared/model/address-normalize/adress-info.model";
import { StatusOut } from "@shared/model/status/status-out.model";
import { RefmPipe } from "@shared/pipes/refm-filler.pipe";
import { CommonService } from "@shared/services/common.service";

@Component({
  selector: "app-address-normalize",
  templateUrl: "./address-normalize.component.html",
  styleUrls: ["./address-normalize.component.scss"]
})
export class AddressNormalizeComponent extends ScreenFormComponent {
  inputAddress: AddressData = new AddressData();
  suggestAddress: AddressData;
  screenInfo: IScreenInfo = null;
  showNormlizationPopup: boolean = false;
  showOverridePopup: boolean = false;
  @Input() forceNormlization: boolean = false;
  @Input() overrideAddress: boolean = false;
  @Input() isDisable: boolean = false;
  @Input() header: string = "Address Formatting";

  @Output() verifyBtnClick: EventEmitter<{
    [key: string]: string | number;
  }> = new EventEmitter();
  @Output() continueBtnClick: EventEmitter<AddressInfo> = new EventEmitter();

  @Input() set address(address: { [key: string]: string }) {
    if (address) {
      this.inputAddress = new AddressData().copy(address);
      this.getAddress(address, this.inputAddress);
      this.onValidate();
    }
  }
  constructor(private commonService: CommonService,
    private refmPipe: RefmPipe) {
    super();
  }
  onDestroy() { }
  onFormValueChange() { }
  onInitialize() {
    this.rootFormGroup = this.buildFormGroup(
      {
        selectedAddressInd: [""]
      },
      FormGroupSection.bodySection
    );
  }
  onViewReady() { }
  addressVerifyBtnClick() {
    this.verifyBtnClick.emit();
  }

  continueClick() {
    const selectedAddressInd = this.rootFormGroup.value.selectedAddressInd;

    if (selectedAddressInd && selectedAddressInd == "S") {
      this.showNormlizationPopup = false;

      const selectedAddress = this.suggestAddress; //[selectedAddressInd];
      const suggestAddr = new AddressInfo().copy(selectedAddress);

      suggestAddr.CityAddr = selectedAddress.CityName;
      suggestAddr.NormalizationCode = "Y";
      suggestAddr["Latitude"] = selectedAddress.GeoLocation.Latitude;
      suggestAddr["Longitude"] = selectedAddress.GeoLocation.Longitude;
      this.continueBtnClick.emit(suggestAddr);
    }
    else {
      this.setDefaultAddress();
    }
  }

  setDefaultAddress() {
    this.showNormlizationPopup = false;
    const formAddr = new AddressInfo().copy(this.inputAddress);
    formAddr.NormalizationCode = "N";
    formAddr.CityAddr = this.inputAddress.CityName;
    this.continueBtnClick.emit(formAddr);
  }

  onOverrideAddress() {
    this.showOverridePopup = false;
    const formAddr = new AddressInfo().copy(this.inputAddress);
    formAddr.NormalizationCode = "O";
    formAddr.CityAddr = this.inputAddress.CityName;
    this.continueBtnClick.emit(formAddr);
  }
  onCancelClick() {
    this.showNormlizationPopup = false;
    //this.setDefaultAddress();
  }
  onCancelOverridePopup() {
    this.setDefaultAddress();
  }
  onValidate() {
    if (this.rootFormGroup) {
      this.setFormValue("", this.rootFormGroup.controls.selectedAddressInd);
      //this.setData();
      this.commonService
        .getStandardAddress(this.inputAddress)
        .subscribe((result: any) => {
          result.validateResponse(
            () => {

             
              
              this.setSuggestedAddress(result.ReturnValue)

              //this.setData();
            },
            () => {
              if (this.forceNormlization) {
                this.doShowMessage(this.doGetErrorMessage("E0050"));
              }
            }
          );
        });
    }
  }


  setData() {
    this.setFormValue(
      "0",
      this.rootFormGroup.controls.selectedAddressInd
    );
    let x = new AddressData()
    // x.Line1Addr = "jerin";
    // x.Line2Addr = "jl";
    // x.StateAddr = "NJ";
    // x.CityAddr = "NJasdadasd";
    // x.CountyIdno = 3;
    // x.ZipAddr = "22222";
    // x.NormalizeCode = "Y";
    this.getAddress(x, x);

    this.showNormlizationPopup = true;
    //this.suggestAddress = [x]

  }

  getAddress(address, obj) {
    obj.CityName = address.CityAddr;
    obj.StateName = this.refmPipe.transform(address.StateAddr, "STATSTAT");
    obj.CountyName = this.refmPipe.transform(address.CountyIdno, "COPTCOPT");
  }

  setSuggestedAddress(data){
    let sAddress = new AddressData().copy(data);
    this.getAddress(data,sAddress);

    this.suggestAddress = sAddress;
    this.setFormValue(
      "S",
      this.rootFormGroup.controls.selectedAddressInd
    );

    this.showNormlizationPopup = true;
  }
}
