import { Component, Input } from "@angular/core";
import { ViewBaseComponent } from "@pebt/uibase";

@Component({
  selector: "app-address-block",
  templateUrl: "./address-block.component.html",
  styleUrls: ["./address-block.component.scss"]
})
export class AddressBlockComponent extends ViewBaseComponent {
  valueStyle: string = "";
  valueFieldText: string = "";
  @Input() labelText: string = "";
  @Input() get valueText() {
    return this.valueFieldText;
  }
  set valueText(value: string) {
    this.valueFieldText = value;
  }
  @Input() labelClass: string = "";
  @Input() styleClass: string = "";
  @Input() get valueClass() {
    return this.valueStyle;
  }
  set valueClass(value: string) {
    if (value) {
      this.valueStyle = value + " labelValue";
    } else {
      this.valueStyle = "";
    }
  }

  constructor() {
    super();
  }
  /**
   * Initialize the View and Variables
   * @returns void
   */
  onInitialize(): void { }
  /**
   * Triggers when form value changes.
   * @returns void
   */
  onViewReady(): void { }
  /**
   * Dispose Resources like Subscribers.
   * @returns void
   */
  onDestroy(): void { }
}
