<!--<div class="wrapper-main">-->
<div class="footer" [class.footer-inner]="innerFooter">

    <app-first-footer *ngIf="showFirstFooter"></app-first-footer>

    <div class="footer-section2 p-d-flex p-flex-column p-flex-md-row p-jc-between">
        <div class="footer-copyright text-small p-order-3 p-order-md-1">Copyright &copy; State of New Jersey, 2023</div>
        <div class="menu-footer p-order-1 p-order-md-2">

            <!-- <app-feedback></app-feedback> -->
            <a [href]="contact" target="_blank">P-EBT Call Center</a> <a
                href="https://www.state.nj.us/nj/privacy.html" target="_blank">Privacy
                Notice</a> <a href="https://www.state.nj.us/nj/legal.html" target="_blank">Legal Statement
                and Disclaimers</a> <a href="https://www.nj.gov/it/ps/07-12-NJOIT_web_accessibility_policy.pdf"
                target="_blank">Accessibility Statement</a>
        </div>
        
    </div>
</div>

