import { Pipe, PipeTransform } from "@angular/core";
import { PebtConstants } from "@pebt/common";
@Pipe({ name: "titleCase" })
export class TitleCasePipe implements PipeTransform {
  transform(input: string, splitter?: string): string {
    const split: string = splitter ? splitter : " ";

    if (!input) {
      return "";
    } else {
      return input
        .toLowerCase()
        .split(split)
        .map(word => {
          return word.replace(word[0], word[0].toUpperCase());
        })
        .join(split);
    }
  }
}
