<header id="header-innerpage">
  <div class="header-innerpage p-d-flex p-jc-between p-flex-column p-flex-md-row"
    [class.fullHeaderStyle]="isFullHeader">
    <div class="header-innerpage-1 logo p-order-2 p-order-md-1 logoStyle" >
      <a (click)="gotoLink()"><img src="assets/images/pebt_logo.svg" class="logo-img" alt="" />
      </a>
      <!-- P-EBT Benefit Search -->
      <div *ngIf="isAdmin" class="agencyClass">Agency Portal</div>
    </div>
    <div class="header-innerpage-2 p-d-flex p-order-1 p-order-md-2">
      <div class="p-mt-2 deptContainer">
      

        <div class="nj-logo paddingBP5 ">
          <div class="njlogoContainer">
            <div class="pull-left logo">
              <a href="https://nj.gov" target="_blank"><img src="assets/images/Seal_of_New_Jersey.svg.png"
                  class="ng-logo-img" alt="" /></a>
            </div>
            <div class="pull-left">
              <a href="https://www.nj.gov/" class="no-underline header1-text" target="_blank">
                <div class="line2 stateFont smallCaps">State Of New Jersey</div>
              </a>
              <a href="https://www.state.nj.us/humanservices/" class="no-underline header1-text" target="_blank">
                <div class="deptFont smallCaps">Department Of Human Services</div>
              </a>
              <a href="https://www.state.nj.us/humanservices/dfd/home/index.html" class="no-underline header1-text"
                target="_blank">
                <div class="smallCaps divFont">Division Of Family Development </div>
              </a>

            </div>
          </div>
        </div>
        <!-- <div class="text-line4 clear translateLink">
          <div  id="translateButton" class="pull-right">
            <a class="link-white translateText" (click)="showLanguage($event)">Translate</a>
          </div>
         

        </div> -->
        <div class="popup user-popup" *ngIf="showLogout">
         
          <lib-button class="p-col-12 login no-pad " label="Log out" (onClick)="onLogoff($event)"></lib-button>
        </div>
      </div>
    </div>
  </div>
</header>


<app-error-panel></app-error-panel>