import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Renderer2,
  HostListener
} from "@angular/core";
import {
  FormError,
  MessageModel,
  UIMessageModel,
  FormGroupSection
} from "@pebt/common";
import { StatusCode } from "@pebt/http";
import { IScreenInfo, ScreenFormComponent } from "@pebt/uibase";
import { AbstractControl } from "@angular/forms";

@Component({
  selector: "app-status-bar",
  templateUrl: "./status-bar.component.html",
  styleUrls: ["./status-bar.component.scss"]
})
export class StatusBarComponent extends ScreenFormComponent {
  screenInfo: IScreenInfo;
  showErrorSideBar: boolean = false;
  isInProgress: boolean = false;
  messageData: any[] = [];
  sideBarMessageData: any[] = [];
  errorPanelPosition: string = "right";
  fieldErrorInfo = null;
  emsgData = null;
  showBlocker = false;
  @Output() onViewChange: EventEmitter<Event> = new EventEmitter();

  constructor() {
    super();
  }

  getIsShowMoreError() {
    if (this.messageData.length > 1) {
      return true;
    }
    if (
      this.messageData.length === 1 &&
      this.messageData[0].formErrrors &&
      this.messageData[0].formErrrors.length > 1
    ) {
      return true;
    }

    return false;
  }

  processShowMessage(message: MessageModel): void {
    switch (message.Code) {
      case StatusCode.Progress:
        this.messageData = [
          {
            description: message.Description
          }
        ].concat(this.messageData);
        this.isInProgress = true;
        break;
      case StatusCode.Completed:
        this.messageData.splice(0, 1);
        this.isInProgress = false;
        break;
      default:
        const messageInfo = {
          type: message.type,
          title: message.Code,
          desc: message.Description
        };
        if (this.isInProgress) {
          this.messageData.push(messageInfo);
        } else {
          this.messageData = [messageInfo];
        }
        break;
    }
  }

  fnShowErrorPanel() {
    this.setFormValue("", this.rootFormGroup.controls.messageSearch);
    setTimeout(() => {
      this.showErrorSideBar = true;
    }, 0);
  }

  fnCloseErrorHeader(event: Event) {
    this.showErrorSideBar = false;

    this.messageData = [];
    this.onViewChange.emit();
  }
  private allFormErrors = [];
  private formErrorIndex = -1;

  onInitialize(): void {    
  }
  onViewReady(): void {
    this.messageService.showMessage$.subscribe((message: MessageModel) => {
      // if (this.messageService.dialogCount > 0) {
      //   return;
      // }
      setTimeout(() => {
        this.processShowMessage(message);
        this.onViewChange.emit();
      }, 0);
    });
   
  }
  addMessageData(message) {
    for (
      let msgIndex = 0, messgeLength = this.messageData.length;
      msgIndex < messgeLength;
      msgIndex++
    ) {
      const element = this.messageData[msgIndex];
      if (
        element.Code !== message.Code &&
        element.Description !== message.Description
      ) {
        this.messageData.push(message);
        break;
      }
    }
  }

  onDestroy(): void {}
  onFormValueChange(data: any): void {}
}
