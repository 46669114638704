<lib-button label="{{ 'verify-address' }}" icon="fa fa-map-marker" (onClick)="addressVerifyBtnClick()"
  [disabled]="isDisable"></lib-button>
<lib-dialog styleClass="secondDialog" [header]="header" [(visible)]="showNormlizationPopup"
  [contentStyle]="{ overflow: 'auto', 'max-height': '800px' }" width="750">
  <div [formGroup]="rootFormGroup">
    <div class="p-grid">
      <lib-label class="p-col-12" text="Your address has been suggested as below"></lib-label>
      <div class="p-grid">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-grid">

          <label class="p-col-12 text-bold"> Entered Address</label>

          <div class="radioBtnStyle ">
            <lib-radio-input class="p-col-12" name="Address" label="" valuecode="E" formControlName="selectedAddressInd"
              inputId="EnteredAddr">
            </lib-radio-input>
          </div>
          <label class="radioBtnLabelStyle p-col" for="EnteredAddr" [innerHTML]="inputAddress | addressPipe:'block'"></label>

        </div>
        <!-- <div class="p-col-6 p-grid" *ngFor="let address of suggestAddress; let i = index"> -->
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-grid">
          <label class="p-col-12  text-bold">Suggested Address</label>
          <div class="radioBtnStyle ">
            <lib-radio-input class="p-col-12" name="Address" label="" valuecode="S"
              formControlName="selectedAddressInd">
            </lib-radio-input>
          </div>
          <label class="radioBtnLabelStyle p-col" for="EnteredAddr" [innerHTML]="suggestAddress | addressPipe:'block'"></label>
        </div>
      </div>
      <br />

      <div class="p-col-12">
        Click on <span class="bold">Confirm</span> to continue or
        <span class="bold">Cancel</span> to edit your address
      </div>
      <!-- <div class="p-col-12" *ngIf="suggestAddress.length <= 1">
        Click on <span class="bold">Confirm</span> to continue or
        <span class="bold">Cancel</span> to edit your address
      </div>
      <div class="p-col-12" *ngIf="suggestAddress.length > 1">
        Select an address and click on <span class="bold">Confirm</span> to
        continue or <span class="bold">Cancel</span> to edit your address
      </div> -->
    </div>
    <!-- <hr /> -->
    <div class="p-grid" *ngIf="false">
      <lib-label class="p-col-12" text="Use Original Address"></lib-label>

      <lib-radio-input name="Address" [label]="inputAddress | addressPipe" valuecode="O" class="p-col-12"
        formControlName="selectedAddressInd">
      </lib-radio-input>
    </div>
    <br />
    <div class="p-col-12 text-right">
      <lib-button class="btn-gap" label="Confirm" (onClick)="continueClick()"></lib-button>
      <lib-button styleClass="black" label="Cancel" (onClick)="onCancelClick()"></lib-button>
    </div>
  </div>
</lib-dialog>

<lib-dialog styleClass="secondDialog" header="Override/Fix Address" [visible]="showOverridePopup" width="510"
  [contentStyle]="{ overflow: 'auto', 'max-height': '750px' }" (onHide)="onCancelOverridePopup()">
  <div>
    <div class="pcolPadding">
      The Address specified could not be normalized. You can choose to override
      and save this by clicking <span class="bold">"Override"</span>. If you
      would like to fix the address and try again, click on
      <span class="bold">"Fix Address"</span>.
    </div>
    <div>
      <div class="p-col-12 p-md-12 p-lg-12 text-right">
        <lib-button class="btn-gap" label="Override" (onClick)="onOverrideAddress()"></lib-button>
        <lib-button styleClass="black" label="Fix Address" (onClick)="onCancelOverridePopup()"></lib-button>
      </div>
    </div>
  </div>
</lib-dialog>