import { Injectable } from "@angular/core";

@Injectable()
export class FieldSizeConstants {
  readonly NAME: number = 100;
  readonly LASTNAME: number = 30;
  readonly FIRSTNAME: number = 16;
  readonly MIDDLENAME: number = 16;
  readonly AGEFROMNUMB: number = 3;
  readonly AGETONUMB: number = 3;
  readonly NAMERECORDDATA: number = 70;
  readonly LINE1ADDR: number = 50;
  readonly LINE2ADDR: number = 50;
  readonly CITYNAME: number = 50;
  readonly SUFFIXNAMECODE: number = 10;
  readonly BIRTHDATE: number = 10;
  readonly PROGRAMMEMBERID: number = 9;
  readonly PROGRAMCASEID: number = 9;
  readonly PAYMENTYEARNUMB: number = 4;
  readonly ATTNADDR: number = 40;
  readonly ASSIGNMENTRIGHTNAME: number = 66;
  readonly APPLICATIONFEETYPEDESC: number = 70;
  readonly APPLICANTCOMMENTNOTE: number = 4000;
  readonly APPLICANTLASTNAME: number = 30;
  readonly APPLICANTFIRSTNAME: number = 16;
  readonly APPLICANTMIDDLENAME: number = 16;
  readonly PHONESTD: number = 5;
  readonly PHONEEXTENSION: number = 6;
  readonly APPLICATIONID: number = 15;
  readonly PLAINTIFFNAME: number = 66;
  readonly DEFENDENTNAME: number = 66;
  readonly COMMENTNOTE: number = 8000;
  readonly ASSISTANCETYPECODE: number = 70;
  readonly STATUSREASON: number = 70;
  readonly PRIMARYFUNCTIONCODE: number = 70;
  readonly DOCKETJUDICALIDC: number = 20;
  readonly CONCEPTIONSTATECODE: number = 60;
  readonly ESTMOTHERPERSONNUMB: number = 10;
  readonly ESTMOTHERLASTNAME: number = 30;
  readonly ESTMOTHERFIRSTNAME: number = 16;
  readonly ESTMOTHERMIDDLENAME: number = 16;
  readonly ESTFATHERPERSONNUMB: number = 10;
  readonly ESTFATHERLASTNAME: number = 30;
  readonly ESTFATHERFIRSTNAME: number = 16;
  readonly ESTFATHERMIDDLENAME: number = 16;
  readonly ESTPARENTMARGCITYNAME: number = 50;
  readonly ESTPARENTMARGSTATECODE: number = 60;
  readonly ESTABLISHMENTSTATECODE: number = 60;
  readonly ESTABLISHMENTDOCKETIDC: number = 17;
  readonly COUNTYCODE: number = 50;
  readonly CRTACTIONPENDINGSTATECODE: number = 60;
  readonly COURTORDERSTATECODE: number = 60;
  readonly TANFASSTCITYNAME: number = 50;
  readonly TANFASSTSTATECODE: number = 60;

  readonly TANFASSTPRIVCITYNAME: number = 50;
  readonly TANFASSTPRIVSTATECODE: number = 60;
  readonly TANFASSTPRIVYEARNUMB: number = 4;
  readonly LASTALIASNAME: number = 30;
  readonly FIRSTALIASNAME: number = 16;
  readonly MIDDLEALIASNAME: number = 16;
  readonly MAIDENNAME: number = 30;
  readonly BIRTHCITYNAME: number = 50;
  readonly BIRTHSTATECODE: number = 60;
  readonly PROGRAMCASENUMB: number = 10;
  readonly NOHOMEPHONESTD: number = 5;
  readonly NOHOMEPHONEEXT: number = 6;
  readonly NOWORKPHONESTD: number = 5;
  readonly NOWORKPHONEEXT: number = 6;
  readonly ADDREMAIL: number = 100;
  readonly CHILDINSCOSTAMT: number = 9;
  readonly OCCUPATIONDESC: number = 200;
  readonly TRADESKILLDESC: number = 200;
  readonly CSTRELATIONTONCPCODE: number = 70;
  readonly MARRIAGECITYNAME: number = 50;
  readonly MARRIAGESTATECODE: number = 60;
  readonly DIVORCECITYNAME: number = 50;
  readonly DIVORCESTATECODE: number = 60;
  readonly HEIGHTFEETNUMB: number = 1;
  readonly HEIGHTINCHNUMB: number = 2;
  readonly WEIGHTNUMB: number = 3;
  readonly LANGUAGECODE: number = 70;
  readonly OTHERLANGUAGEDESC: number = 50;
  readonly DISABILITYNOTEDESC: number = 200;
  readonly AUTHREPNAME: number = 66;
  readonly LICENSEDRIVERNUMBIDC: number = 32;
  readonly LICENSEISSUESTATECODE: number = 60;
  readonly FVCOMMENTNOTE: number = 4000;
  readonly RESIDECOUNTYCODE: number = 50;
  readonly OTHERPARTYID: number = 10;
  readonly POLICYIDC: number = 10;
  readonly GROUPIDC: number = 10;
  readonly IVEPARTYNUMB: number = 9;
  readonly ADDITIONALNOTE: number = 4000;
  readonly NCPRELATIONTOCSTCODE: number = 70;
  readonly SUPPORTCITYNAME: number = 50;
  readonly SUPPORTSTATECODE: number = 60;
  readonly OTHEROFFICECITYNAME: number = 50;
  readonly OTHEROFFICESTATECODE: number = 60;
  readonly VEHICLEMODELNAME: number = 20;
  readonly VEHICLEMAKENAME: number = 20;
  readonly PASTVEHICLEMAKENAME: number = 64;
  readonly VEHICLEYEARNUMB: number = 4;
  readonly VEHICLELICENSEIDC: number = 10;
  readonly PASTVEHICLELICENSEIDC: number = 32;
  readonly VEHICLEASSESSEDSTATECODE: number = 60;
  readonly PHONENUMB: number = 15;
  readonly CSTRELATIONTOCHILDCODE: number = 70;
  readonly NCPRELATIONTOCHILDCODE: number = 70;
  readonly TASKNAME: number = 100;
  readonly REFERENCETYPECODE: number = 2;
  readonly UPDATEDBYNAME: number = 30;
  readonly ASSIGNEDUSERNAME: number = 30;
  readonly TASKSD: number = 100;
  readonly EVALUATIONDESC: number = 200;
  readonly OUTCOMEDESC: number = 200;
  readonly NEXTTASKNAME: number = 100;
  readonly DCFSPERSONNUMB: number = 10;
  readonly CASETYPECODE: number = 60;
  readonly CSTNAMERECORDDATA: number = 60;
  readonly NCPADDRESSRECORDDATA: number = 60;
  readonly CASEDOCKETINFORMATION: number = 10;
  readonly SCREENCODE: number = 4;
  readonly OTHERPARTYNAME: number = 60;
  readonly OTHERPARTYTYPECODE: number = 70;
  readonly FEINNUMB: number = 9;
  readonly SEINNUMB: number = 12;
  readonly VERIFIEDBYNAME: number = 30;
  readonly SEARCHOPTIONNAMETEXT: number = 70;
  readonly DOINGBUSINESSASNAME: number = 50;
  readonly SEARCHOPTIONBUSINESSNAMETEXT: number = 70;
  readonly OTHPSOISOURCECODE: number = 3;
  readonly BUSINESSTYPEDESC: number = 30;
  readonly CONTACTNAME: number = 66;
  readonly OTHERPARTYEML: number = 100;
  readonly WEBSITEURL: number = 1000;
  readonly OTHERPARTYCOUNTRYSTDNUMB: number = 5;
  readonly PHONEEXTNNUMB: number = 6;
  readonly FAXNUMB: number = 15;
  readonly CORPORATEOFFICE: number = 10;
  readonly CORPORATEOFFICENAME: number = 60;
  readonly HEALTHPLANADMINISTRATOR: number = 10;
  readonly HEALTHPLANADMINISTRATORNAME: number = 60;
  readonly EMPLOYEMENTVERIFICATION: number = 10;
  readonly EMPLOYEMENTVERIFICATIONNAME: number = 60;
  readonly ADMINISTRATIVEOFFICE: number = 10;
  readonly ADMINISTRATIVEOFFICENAME: number = 60;
  readonly PAYROLLOFFICE: number = 10;
  readonly PAYROLLOFFICENAME: number = 60;
  readonly EMPLOYERBENEFITS: number = 10;
  readonly EMPLOYERBENEFITSNAME: number = 60;
  readonly INCOMEWITHHOLDING: number = 10;
  readonly INCOMEWITHHOLDINGNAME: number = 60;
  readonly OTHPNOTE: number = 4000;
  readonly PARTICIPANTNAME: number = 62;
  readonly COUNTYNAME: number = 52;
  readonly TASKCODE: number = 5;
  readonly CSTFULLNAME: number = 66;
  readonly NCPFULLNAME: number = 66;
  readonly IVDOFFICECODE: number = 2;
  readonly IVDOFFICENAME: number = 2;
  readonly REFERRALREASONCODE: number = 3;
  readonly REFERRALREASONDESC: number = 3;
  readonly ADDRESSSOURCECODE: number = 3;
  readonly BANKRUPTCYDOCKETIDC: number = 17;
  readonly JUDGENAME: number = 60;
  readonly PLANADMINOTHPID: number = 10;
  readonly BANKRUPTCYCOURTOTHPID: number = 10;
  readonly BANKATTORNEYOTHPID: number = 10;
  readonly FAMILYPERSONEML: number = 100;
  readonly INMATEIDC: number = 9;
  readonly PROBATIONOFFICERNAME: number = 50;
  readonly OFFICEREML: number = 100;
  readonly CONCEPTIONCITYNAME: number = 50;
  readonly BIRTHCERTIFICATEIDC: number = 20;
  readonly PASSPORTIDC: number = 10;
  readonly ALIENREGISTNIDC: number = 10;
  readonly SPOUSENAME: number = 40;
  readonly DIVORCEDOCKETIDC: number = 17;
  readonly PERSONEML: number = 100;
  readonly DEATHCERTIFICATEDESC: number = 20;
  readonly IDENTIFICATIONMARKDESC: number = 200;
  readonly ADDITIONALCOMMENTNOTE: number = 4000;
  readonly SCHOOLNAMEDESC: number = 200;
  readonly DISABILITYOTHERDESC: number = 200;
  readonly BANKRUPTCYOTHERPARTYNAME: number = 60;
  readonly BANKATTORNEYOTHERPARTYNAME: number = 60;
  readonly PROBATIONID: number = 10;
  readonly CHILDID: number = 10;
  readonly INSURANCEPOLICYIDC: number = 20;
  readonly INSURANCEOTHPNAME: number = 60;
  readonly INSURANCEGROUPIDC: number = 25;
  readonly ACCOUNTIDC: number = 30;
  readonly ASSETVALUEAMT: number = 9;
  readonly PRIMARYACCOUTNAME: number = 50;
  readonly SECONDARYACCOUNTNAME: number = 50;
  readonly FINOTHERPARTYID: number = 10;
  readonly OTHERASSETNOTE: number = 4000;
  readonly REALESTATEINSTRUMENTNUMB: number = 9;
  readonly ASSETLOTIDC: number = 5;
  readonly ASSETBOOKIDC: number = 5;
  readonly ASSETPAGEIDC: number = 5;
  readonly TAXMAPIDC: number = 20;
  readonly LIENOTHERPARTYID: number = 10;
  readonly MORTGAGEAMT: number = 11;
  readonly MORTGAGEBALAMT: number = 11;
  readonly INSURANCECLAIMIDC: number = 30;
  readonly POTENTIALSETTLEAMT: number = 12;
  readonly SETTLEMENTAMT: number = 12;
  readonly INSOTHERPARTYID: number = 10;
  readonly ATTYOTHERPARTYID: number = 10;
  readonly COMMENTDESC: number = 4000;
  readonly VEHICLEREGISTRATIONIDC: number = 27;
  readonly RECEIPNUMBER: number = 27;
  readonly VEHICLECOLORNAME: number = 25;
  readonly VEHICLEINSTRUMENTNUMB: number = 9;
  readonly VEHICLEIDENTIDC: number = 32;
  readonly VEHICLEWHOLESALEVALUEAMT: number = 11;
  readonly ASSESSEDFIPSCOUNTYCODE: number = 50;
  readonly VEHICLEVALUEASSESSEDAMT: number = 11;
  readonly VEHICLELIENACCNUMB: number = 30;
  readonly VEHICLELIENAMT: number = 11;
  readonly OWNERNAME: number = 60;
  readonly ASSETSEQNUMB: number = 3;
  readonly ASSETSUBTYPECODE: number = 3;
  readonly OTHPAGENTID: number = 10;
  readonly LICENSETYPECODE: number = 3;
  readonly LICENSEDESCRIPTIONTYPEDESC: number = 200;
  readonly LICENSEIDC: number = 32;
  readonly GAMINGREGISTRATIONIDC: number = 8;
  readonly BUSINESSNAME: number = 50;
  readonly TRADENAME: number = 50;
  readonly LICENSETYPEDESC: number = 3;
  readonly PSOICLAIMACCOUNTNUMB: number = 9;
  readonly PSOIBENEFICIARYIDC: number = 3;
  readonly MILITARYNUMB: number = 10;
  readonly VASERVICENUMB: number = 9;
  readonly PSOIOCCUPATIONDESC: number = 200;
  readonly PSOITRADESKILLDESC: number = 200;
  readonly PSOISCHEDULESHIFTOTHERDESC: number = 200;
  readonly PSOIWORKHOURNUMB: number = 10;
  readonly PSOIGROSSWAGEAMT: number = 11;
  readonly PSOIRATEOFPAYAMT: number = 11;
  readonly PSOINETPAYAMT: number = 11;
  readonly PSOITOTALINSURANCECOSTAMT: number = 11;
  readonly PRINTMETHODCODE: number = 70;
  readonly DELIVERYMETHODCODE: number = 70;
  readonly DOCUMENTIDC: number = 10;
  readonly DOCUMENTDESC: number = 110;
  readonly DOCUMENTSUBSYSTEMCODE: number = 70;
  readonly DOCUMENTTYPECODE: number = 70;
  readonly COMMITFREQNUMB: number = 5;
  readonly EXCEPTIONTHRESHOLDNUMB: number = 5;
  readonly SERVERPATHNAME: number = 60;
  readonly FILENAME: number = 60;
  readonly JOBCODE: number = 7;
  readonly JOBDESC: number = 200;
  readonly PROCESSNAME: number = 100;
  readonly ROLENAME: number = 5;
  readonly FULLSUPERVISORNAME: number = 60;
  readonly USERSUBSTITUTENAME: number = 60;
  readonly ALPHARANGEFROMCODE: number = 5;
  readonly CASEASSIGNEDNUMB: number = 5;
  readonly OFFICENAME: number = 60;
  readonly WORKPHONENUMB: number = 15;
  readonly USERTITLECODE: number = 2;
  readonly USERTITLEDESC: number = 70;
  readonly USEREML: number = 100;
  readonly USERNAME: number = 30;
  readonly STATENAME: number = 60;
  readonly OTHERSTATECASEIDC: number = 15;
  readonly FARCOMMENTNOTE: number = 4000;
  readonly MATCHCASENUMB: number = 10;
  readonly OTHERSTATEFIPSNAME: number = 60;
  readonly FARDESC: number = 4000;
  readonly PETITIONERNAME: number = 65;
  readonly RESPONDENTNAME: number = 65;
  readonly REASONCODE: number = 5;
  readonly STATEFIPSCODE: number = 2;
  readonly OTHERSTATEDOCKETIDC: number = 15;
  readonly CONTACTFIRSTNAME: number = 16;
  readonly AGENCYNAME: number = 40;
  readonly CONTACTLASTNAME: number = 30;
  readonly CONTACTMIDDLENAME: number = 16;
  readonly CONTACTSUFFIXNAME: number = 3;
  readonly CITYCODE: number = 50;
  readonly CONTACTEML: number = 100;
  readonly FORMERCASENUMB: number = 10;
  readonly REASONNOTES: number = 4000;
  readonly INSCARRIERNAME: number = 30;
  readonly INSPOLICYDESC: number = 17;
  readonly WAGEAMT: number = 9;
  readonly AGENCYREPORTINGIDC: number = 9;
  readonly AGENCYREPORTINGNAME: number = 70;
  readonly EMPLOYERNUMB: number = 10;
  readonly EMPLOYERNAME: number = 60;
  readonly EMPQUARTERLYTIPAMT: number = 9;
  readonly EXPENSEAMT: number = 9;
  readonly INCOMEAMT: number = 9;
  readonly PROCESSEDRECORDCOUNTNUMB: number = 9;
  readonly COUNTYFIPSCODE: number = 3;
  readonly OFFICEFIPSCODE: number = 2;
  readonly FIPSSUBTYPECODE: number = 3;
  readonly FIPSNAME: number = 50;
  readonly CONTACTTITLENAME: number = 40;
  readonly FIPSSUBTYPEDESC: number = 70;
  readonly TASKDESC: number = 100;
  readonly NEXTTASKCODE: number = 5;
  readonly DOCUMENTCOUNTNUMB: number = 3;
  readonly PROCESSCODE: number = 4;
  readonly TASKDUEDAYNUMB: number = 3;
  readonly AMOUNTTYPECODE: number = 4;
  readonly AMOUNTTYPEDESC: number = 70;
  readonly ENTITYNAME: number = 30;
  readonly ATTRIBUTENAME: number = 30;
  readonly REFERENCECODE: number = 10;
  readonly REFERENCEDESC: number = 70;
  readonly PERCENTAGETYPECODE: number = 4;
  readonly GOALPCT: number = 3;
  readonly FEEAMT: number = 9;
  readonly ATTRIBUTEDESC: number = 70;
  readonly REFERENCEPCT: number = 3;
  readonly CASEIDENTIFIERDESC: number = 100;
  readonly PERSONIDENTIFIERDESC: number = 100;
  readonly BROADCASTTITLENAME: number = 100;
  readonly BROADCASTDESC: number = 400;
  readonly CREATEDUSERNAME: number = 30;
  readonly ERRORDESC: number = 300;
  readonly ERRORCODE: number = 8;
  readonly CASEID: number = 10;
  readonly NOMADSUPINUMB: number = 9;
  readonly PSOIUNIONMEMBERSHIPDESC: number = 200;
  readonly OFFICECODE: number = 60;
  readonly DOCKETIDC: number = 17;
  readonly STATECODE: number = 60;
  readonly COUNTRYCODE: number = 70;
  readonly SOINSTATECODE: number = 2;
  readonly SOINCOUNTRYCODE: number = 2;
  readonly SOURCECODE: number = 70;
  readonly LOCATERESOLUTIONSTATUSCODE: number = 70;
  readonly CHILDLASTNAME: number = 30;
  readonly CHILDFIRSTNAME: number = 16;
  readonly VISISTATUSCODE: number = 70;
  readonly CHILDPERSONID: number = 10;
  readonly CHILDMIDDLENAME: number = 16;
  readonly CHILDSUFFIXCODE: number = 70;
  readonly CHILDSSNNUMB: number = 11;
  readonly CHILDGENDERCODE: number = 70;
  readonly CHILDBIRTHSTATECODE: number = 2;
  readonly CHILDBIRTHCITYCODE: number = 70;
  readonly BIRTHSTATUSCODE: number = 70;
  readonly MOTHERPERSONID: number = 10;
  readonly MOTHERLASTNAME: number = 30;
  readonly MOTHERFIRSTNAME: number = 16;
  readonly MOTHERMIDDLENAME: number = 16;
  readonly MOTHERSUFFIXCODE: number = 70;
  readonly MOTHERSSNNUMB: number = 11;
  readonly MOTHERMARRIEDCODE: number = 70;
  readonly FATHERPERSONID: number = 10;
  readonly FATHERLASTNAME: number = 30;
  readonly FATHERFIRSTNAME: number = 16;
  readonly FATHERMIDDLENAME: number = 16;
  readonly FATHERSUFFIXCODE: number = 70;
  readonly FATHERSSNNUMB: number = 11;
  readonly MOTHERMAILLINE1ADDR: number = 50;
  readonly MOTHERMAILLINE2ADDR: number = 50;
  readonly MOTHERMAILCITYADDR: number = 50;
  readonly MOTHERMAILSTATECODE: number = 2;
  readonly MOTHERMAILPOSTCODE: number = 15;
  readonly MOTHERMAILCOUNTYCODE: number = 50;
  readonly MOTHERMAILCOUNTRYCODE: number = 2;
  readonly RESUBMITDAYNUM: number = 2;
  readonly RESPONSEDAYNUM: number = 2;
  readonly POSTCODE: number = 15;
  readonly SLOTNUM: number = 3;
  readonly HOLIDAYDISC: number = 200;
  readonly CHILDBIRTHCERTIFICATEIDC: number = 6;
  readonly PERSONID: number = 10;
  readonly PERSONNAME: number = 66;
  readonly LABNAME: number = 60;
  readonly TESTLOCATIONID: number = 10;
  readonly LOCATIONSTATECODE: number = 2;
  readonly PROBABILITYPCT: number = 3;
  readonly CASEWELFARENUMB: number = 9;
  readonly WELFAREYEARMONTHNUMB: number = 7;
  readonly MTDASSISTEXPENDAMT: number = 9;
  readonly MTDASSISTREIMBURSEDAMT: number = 9;
  readonly RECEIPTSAPPLTOARRAMT: number = 9;
  readonly APPPRIORMONTHBALAMT: number = 9;
  readonly LTDASSISTREIMBURSEDAMT: number = 9;
  readonly LTDASSISTEXPENDAMT: number = 9;
  readonly URABALANCEAMT: number = 9;
  readonly PETITIONERCAPTION: number = 240;
  readonly RESPONDENTCAPTION: number = 240;
  readonly INSTRUMENTIDC: number = 25;
  readonly SSNNUMB: number = 9;
  readonly REFRENCEIDC: number = 20;
  readonly DEVIATIONAMT: number = 9;
  readonly GUIDELINEDESC: number = 50;
  readonly EMPLOYERFINEAMT: number = 9;
  readonly CONTROLLINGSTATECODE: number = 2;
  readonly CONTROLLINGDOCKETIDC: number = 17;
  readonly INTERGOVERNMENTALORDERIDC: number = 15;
  readonly CSTMEDPERCENTAGENUMB: number = 3;
  readonly NCPMEDPERCENTAGENUMB: number = 3;
  readonly COVERAGEOTHERDESC: number = 50;
  readonly HEARINGMASTERNAME: number = 60;
  readonly CURRENCYDESC: number = 15;
  readonly EXCHANGEAMT: number = 9;
  readonly UNTITLEDIVDATTORNEY: number = 66;
  readonly UNTITLEDDWSS: number = 66;
  readonly FURTHERFINDINGDESC: number = 700;
  readonly USERNOTE: number = 4000;
  readonly BONDORDEREDAMT: number = 9;
  readonly BONDIDC: number = 10;
  readonly BONDISSUEDAMT: number = 9;
  readonly BONDCURRENTBALANCEAMT: number = 9;
  readonly SERVICEPARTY: number = 65;

  readonly CHILDPARTICIPANT: number = 61;
  readonly MATCHDATE: number = 10;
  readonly NOTETITLEDESC: number = 200;

  readonly TRANSACTIONSTATUSDESC: number = 70;
  readonly OTHERSTATEFIPSCODE: number = 2;

  readonly RECIPIENTTYPE: number = 70;
  readonly REQUESTEDBYUSERNAME: number = 30;
  readonly RECIPIENTNAME: number = 65;
  readonly RECIPIENTIDC: number = 10;
  readonly CASEPARTYROLECODE: number = 70;
  readonly ASSIGNEDOFFICENAME: number = 50;
  readonly CASESTATUSCODE: number = 70;
  readonly CASECLOSUREREASONCODE: number = 70;
  readonly DUEDATE: number = 10;
  readonly OBLIGATIONBEGINDATE: number = 10;
  readonly OBLIGATIONENDDATE: number = 10;
  readonly PERIODICFREQUENCYCODE: number = 70;
  readonly FILEDDATE: number = 10;
  readonly ORDERENDDATE: number = 10;
  readonly IWOTYPECODE: number = 70;
  readonly INSURANCEORDEREDCODE: number = 70;
  readonly GUIDELINEAMT: number = 9;

  readonly RECEIPSOURCE: number = 2;
  readonly ACCOUNTNUMBER: number = 17;
  readonly BANKROUTINGNUMBER: number = 10;
  readonly ITEMNUMBER: number = 10;
  readonly NOTES: number = 328;
  readonly BANKNAME: number = 50;
  readonly EFFECTIVEDATE: number = 10;
  readonly ENDDATE: number = 10;
  readonly COMPLIANCETYPECODE: number = 2;
  readonly COMPLIANCETYPE: number = 70;
  readonly COMPLIANCEPROVISIONCODE: number = 2;
  readonly COMPLIANCEPROVISION: number = 70;
  readonly STATUSCODE: number = 2;
  readonly STATUS: number = 70;
  readonly COMPLIANCEAMT: number = 11;
  readonly BANKACCOUNTIDC: number = 17;
  readonly DISBURSEMEDIUMCODE: number = 1;
  readonly ACCOUNTSTATUSCODE: number = 2;
  readonly ROUTINGBANKNAMECODE: number = 9;
  readonly SWIFTIDC: number = 11;
  readonly ACCOUNTTYPECODE: number = 3;
  readonly EFTREASONCODE: number = 3;
  readonly EFTREASONDESC: number = 70;
  readonly DISBURSEMENTDATE: number = 10;
  readonly FUNDSRECIPIENTID: number = 10;
  readonly FROMDATE: number = 10;
  readonly THROUGHDATE: number = 10;
  readonly DISBURSEAMT: number = 9;
  readonly CHECKNUMB: number = 9;
  readonly CONTROLNUMB: number = 9;
  readonly METHOD: number = 74;
  readonly CHECKORCONTROL: number = 9;
  readonly STATUSDATE: number = 10;

  readonly ACTUALAMT: number = 3;
  readonly CONTROLAMT: number = 3;
  readonly BATCHNUM: number = 4;
  readonly PERDISBUSRTMENT: number = 11;
  readonly DATE: number = 10;
  readonly ACTIONCODE: number = 4;
  readonly ACTION: number = 70;
  readonly RECEPIT: number = 27;
  readonly REASON: number = 70;
  readonly UPDATEDBY: number = 30;
  readonly SERVICEDOC: number = 110;
  readonly HELDAMOUNT: number = 10;
  readonly HELDREASON: number = 4;
  readonly UDCDESC: number = 70;
  readonly REASONSTATUSCODE: number = 4;
  readonly CHECKRECIPIENTID: number = 10;
  readonly BIRTHCERTIFICATENUMBER: number = 6;
  readonly SCADURECEIPTNUMBER: number = 12;
  readonly ASSESSEDYEARNUMB: number = 4;
  readonly ASSESSEDAMT: number = 9;
  readonly PAIDAMT: number = 9;
  readonly WAIVEDAMT: number = 9;
  readonly REFUNDRECEPIENT: number = 10;
  readonly AMOUNT: number = 9;
  readonly PAYORNAME: number = 71;
  readonly RECOUPMENTCODE: number = 25;
  readonly DEPOSIT: number = 3;
  readonly BATCH: number = 1;
  readonly RECEIPT: number = 27;
  readonly RECEIPTTYPE: number = 76;
  readonly RECEIPTSOURCE: number = 2;
  readonly RECEIPTDATE: number = 10;
  readonly RECEIPTAMOUNT: number = 9;
  readonly PAYORID: number = 10;
  readonly TRANSDATE: number = 10;
  readonly TRANSAMOUNT: number = 11;
  readonly RECEIPTSTATUS: number = 74;
  readonly PAYORMEMBERNAME: number = 64;
  readonly COUNTY: number = 46;
  readonly REVERSEDSTATUS: number = 77;
  readonly HELDREASONSTATUS: number = 77;
  readonly RELEASEAMT: number = 11;
  readonly REVERSALDATE: number = 10;
  readonly CHECKIDC: number = 10;
  readonly POSTINGLEVEL: number = 74;
  readonly DEPOSITSOURCE: number = 70;
  readonly FIPS: number = 7;
  readonly USER: number = 30;
  readonly REVERSEDBY: number = 30;
  readonly REVERSALREASON: number = 75;
  readonly HELDDATE: number = 10;
  readonly NOTE: number = 4000;
  readonly DISTRIBUTEDTO: number = 44;
  readonly PGTYPE: number = 74;
  readonly DISTRIBUTEDAS: number = 78;
  readonly IVACASEID: number = 10;
  readonly FUNDRECIPIENTID: number = 10;
  readonly TYPE: number = 74;
  readonly RECEIPTACTION: number = 77;
  readonly FUNDRECIPIENTNAME: number = 64;
  readonly RECOUPMENT: number = 74;
  readonly CHECKCONTROL: number = 18;
  readonly DISBURSESTATUS: number = 75;
  readonly YEAR: number = 4;
  readonly FEETYPE: number = 75;
  readonly GUIDELINENAME: number = 15;
  readonly ADDRESSSOURCEDESC: number = 70;
  readonly FULLNAME: number = 60;
  readonly POLICYHOLDERNAME: number = 66;
  readonly MEMBERIDC: number = 25;
  readonly OTHERINSDESC: number = 50;
  readonly MESSAGEDESC: number = 200;
  readonly VARIATIONAMT: number = 9;
  readonly FROMMONTHNUMB: number = 2;
  readonly TOMONTHNUMB: number = 2;
  readonly FROMYEARNUMB: number = 4;
  readonly TOYEARNUMB: number = 4;
  readonly PAYMENTONARREARAMT: number = 9;
  readonly PERIODICAMT: number = 9;
  readonly PRINCIPALADJUDICATEDAMT: number = 9;
  readonly PRINCIPALUNADJUDICATEDAMT: number = 9;
  readonly INTERESTADJUDICATEDAMT: number = 9;
  readonly INTERESTUNADJUDICATEDAMT: number = 9;
  readonly PENALTYADJUDICATEDAMT: number = 9;
  readonly PENALTYUNADJUDICATEDAMT: number = 9;
  readonly TOTALAMT: number = 9;
  readonly OWEADJNAAAMT: number = 9;
  readonly OWEUNADJNAAAMT: number = 9;
  readonly OWEADJINTNAAAMT: number = 9;
  readonly OWEUNADJONAANAAAMT: number = 9;
  readonly OWEUNADJONUANAAAMT: number = 9;
  readonly OWEADJPAAAMT: number = 9;
  readonly OWEUNADJPAAAMT: number = 9;
  readonly OWEADJINTPAAAMT: number = 9;
  readonly OWEUNADJONAAPAAAMT: number = 9;
  readonly OWEUNADJONUAPAAAMT: number = 9;
  readonly OWEADJUDAAMT: number = 9;
  readonly OWEUNADJUDAAMT: number = 9;
  readonly OWEADJINTUDAAMT: number = 9;
  readonly OWEUNADJONAAUDAAMT: number = 9;
  readonly OWEUNADJONUAUDAAMT: number = 9;
  readonly OWEADJIVEFAMT: number = 9;
  readonly OWEUNADJIVEFAMT: number = 9;
  readonly OWEADJINTIVEFAMT: number = 9;
  readonly OWEUNADJONAAIVEFAMT: number = 9;
  readonly OWEUNADJONUAIVEFAMT: number = 9;
  readonly OWEADJMEDIAMT: number = 9;
  readonly OWEUNADJMEDIAMT: number = 9;
  readonly OWEADJINTMEDIAMT: number = 9;
  readonly OWEUNADJONAAMEDIAMT: number = 9;
  readonly OWEUNADJONUAMEDIAMT: number = 9;
  readonly OWEADJNIVDAMT: number = 9;
  readonly OWEUNADJNIVDAMT: number = 9;
  readonly OWEADJINTNIVDAMT: number = 9;
  readonly OWEUNADJONAANIVDAMT: number = 9;
  readonly OWEUNADJONUANIVDAMT: number = 9;
  readonly MERGEPARTICIPANTNAME: number = 66;
  readonly PARTICIPANTID: number = 10;
  readonly MERGESSN: number = 11;
  readonly MERGECASE: number = 74;
  readonly ASSETSUBTYPEDESC: number = 70;
  readonly TASKIDENTIFIER: number = 14;
  readonly FIPSCODE: number = 7;
  readonly EVENTTYPECODE: number = 3;
  readonly EVENTNAME: number = 70;
  readonly RECEIPENTNODESC: number = 31;
  readonly CHILDNAME: number = 62;
  readonly PERCENT: number = 3;
  readonly SOURCEOFINCOMENAME: number = 28;
  readonly DOCKETNO: number = 17;
  readonly SCANBATCHNUMBER: number = 50;
  readonly SUPPORTYEARMONTHNUMB: number = 6;
  readonly RECEIPTIDC: number = 27;
  readonly RECEIPTSOURCECODE: number = 2;
  readonly TRANSACTIONAMT: number = 9;
  readonly DOCUMENTNAME: number = 100;
  readonly ENTITYDESC:number=200;
  readonly RESOURCEWORTHAMOUNT:number=9;
  readonly ACCOUNTID: number = 15;
  readonly ADDRLINE: number = 50;
  readonly TYPEVEHICLECODE: number = 2;

}
