
import { Component, HostListener, Renderer2 } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";

import { OutData } from "@pebt/common";
import { IScreenInfo, ScreenComponent } from "@pebt/uibase";
import { AppConstants } from "@shared/constants/app-constants";
import { DataStoreService } from "@shared/services/data-store.service";
import { UserInactiveService } from "@shared/services/user-inactive.service";
import { Subscription } from "rxjs";
import { appConfig } from "src/config";
import { AppInitService } from "./app-init.service";
import { ThemingService } from "@shared/services/theming.service";
import { CommonService } from "@shared/services/common.service";
import { TranslatorService } from "@shared/services/translator.service";
// import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from "@angular/common/http";
import { DataLayerService } from "@shared/services/data-layer.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent extends ScreenComponent {
  screenInfo: IScreenInfo;
  private leftKey = 37;
  private idleSubscription: Subscription;
  showTranslatePanel: boolean = false;
  timerHandle: ReturnType<typeof setTimeout>;
  subscription: any;

  transPanelTop = 330;
  transPanelRight = 0;
  scrollHeight: number = 0;
  showScrollBtn: boolean = false;
  /**
   *
   */
  constructor(
    private dataStore: DataStoreService,    
    private idleService: UserInactiveService,
    router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private appInitService: AppInitService,
    private themingService: ThemingService,
    private commonService: CommonService,
    private translatorService: TranslatorService,   
    private httpClient: HttpClient,
    private _dataLayerService: DataLayerService
  ) {
    super();
   
    if (!this.storageService.getItem("EMSG")) {
      this.storageService.setItem("EMSG", JSON.stringify({}));
    }

    router.errorHandler = (error: any) => {
      console.log(error);
      this.doShowMessage("The Page is Under Construction");
    };

    this.themingService.setTheme("blue");
    
    if (!this.storageService.getItem("access_token") || this.storageService.getItem("access_token") == "")
      this.storageService.setItem("access_token", "test");

    this.appInitService.init().then(() => { });
    this.checkQueryString();
    this.activatedRoute.queryParams.subscribe(params => {
      if ((params["nswd1"], params["nswd2"])) {
        this.doNavigate("login", {
          loginInfo: {
            userName: params["nswd2"],
            userNumber: params["nswd1"]
          }
        });
      }
    });
    this.renderer.listen(document, "contextmenu", event => {
      event.preventDefault();
    });

    this.router.events.subscribe(event => {
      if (window.scrollY > 0) {
        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationStart) {
        this.showTranslatePanel = false;
      }

      if (event instanceof NavigationEnd) {
        const url = event.url;
        let screenName = "";
        switch (url) {
          case "/pebt/search":
            screenName = "Search";
            break;
          case "/pebt/result":
            screenName = "Result";
            break;
        }
        //call our dataLayer service's page view method to ping home with the url value.
        this._dataLayerService.logPageView(url, screenName); 
      }     
    });   

    this.subscription = translatorService.translator.subscribe(record => {
      switch (record.key) {
        case "HIDE_LANG":
          this.showTranslatePanel = false;
          break;
        case "SHOW_LANG":
          this.showTranslatePanel = true;
          this.transPanelTop = record.yPos;
          this.transPanelRight = record.xPos;
          break
      }
    });

   
    // translate.setDefaultLang("en");
    // translate.addLangs(["en", "es"]);
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : "en");


  }

  @HostListener("window:beforeunload")
  clearStorage() {
   
  }
  @HostListener("document:keydown", ["$event"]) onKeyDown(
    event: KeyboardEvent
  ) {
    // Prevent Alt+,
    if (event.altKey && event.which === this.leftKey) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }


  loadDataStore(): Promise<void> {
    return this.appInitService.init();
  }

  checkQueryString() {
    const queryParams = location.search.split("&");
    let userName = null;
    let userNumber = null;
    for (let queryIndex = 0; queryIndex < queryParams.length; queryIndex++) {
      const element = queryParams[queryIndex].split("=");
      if (element.length > 1) {
        if (element[0].indexOf("nswd2") !== -1) {
          userName = element[1];
        }
        if (element[0].indexOf("nswd1") !== -1) {
          userNumber = element[1];
        }
      }
    }
    if (userName && userNumber) {
      this.doNavigate("login", {
        loginInfo: {
          userName: userName,
          userNumber: userNumber
        }
      });
    }
  }

  onInitialize(): void {
    let emsg = {
      E0012: "No Matching Records Found",
      E0058: "No Data Found",
      E0076: "Enter Required Information",
      E0077: "Enter Valid District"
    };
    this.storageService.setItem("EMSG", JSON.stringify(emsg));
    this.storageService.setItem("TRANSFORM", JSON.stringify({}));
    if (!this.storageService.getItem("access_token") || this.storageService.getItem("access_token") == "")
      this.storageService.setItem("access_token", "test");

    this.resizeWindow();
  }

  timer;
  onViewReady(): void {
    this.commonService.bodyContentHeight = window.innerHeight - (173 + 83);
    this.setScrollHeight();
    this.timer = setInterval(() => {
      let languageElem = document.getElementsByClassName('goog-te-combo');
      
      if (languageElem != null && languageElem.length > 0) {
        clearInterval(this.timer)
        languageElem[0].addEventListener('change', () => {
          this.setLanguage();
        })
        setTimeout(() => {
          this.setLanguage();
        }, 1000)

      }
    }, 1000);

    // const lang = this.translate.getBrowserLang();
    // this.translate.use(lang);
    // console.log("lang on refresh ::: " + lang);
    // console.log(this.translate);
    // console.log("---------------------------------------")
  }


  /**
   *  Opening in Popup so Onload Maximize the Window
   * @returns void
   */
  resizeWindow(): void {
    window.moveTo(0, 0);
    window.resizeTo(screen.availWidth, screen.availHeight);
    window.focus();
    this.showTranslatePanel = false;
    this.setScrollHeight()
  }

  setScrollHeight() {
    this.scrollHeight = ((window.innerHeight * 20) / 100)
  }
  onDestroy(): void {
   
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showTranslatePanel = false;
    this.setScrollHeight();
  }


  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.showScrollBtn = true;
    }
    else if (this.showScrollBtn && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.showScrollBtn = false;
    }
  }
  
  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 4));
      }
    })();
  }

  TranslateClose() {
    this.setLanguage();

    this.showTranslatePanel = false;
  }

  setLanguage() {

    const languageElem: any = document.getElementsByClassName('goog-te-combo');
    console.log(languageElem[0].value)
    let lang = languageElem[0].value;
    lang = (lang == "") ? "en" : lang;
    console.log("Lang ::: " + lang);
  
    //this.translate.use(lang);
    

    this.storageService.setItem("Language", lang);
    //this.commonService.SaveApplicationLanguage();
    //this.benefitService.updateTransaltion();
    this.showTranslatePanel = false;
  }
}
