import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
  name: "relatedField"
})
export class RelatedFieldPipe implements PipeTransform {
  private predefinedValue = {
    personId: "Participant ID",
    caseId: "Case ID"
  };
  transform(value: string, isReport?: any): any {
    // if (this.predefinedValue[value]) {
    //   return this.predefinedValue[value];
    // }

    return value;
  }
}
