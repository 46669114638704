import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { StorageService } from "@pebt/storage";
import { DataStoreService } from "@shared/services/data-store.service";
import { addSeconds } from "date-fns";
import { interval } from "rxjs";
import { appConfig } from "../../../config";

@Injectable()
export class OperatingHourGuard implements CanActivate {
  private dateTime;
  private startTime: string[];
  private endTime: string[];
  private isStarted = false;
  constructor(
    private router: Router,
    private storageService: StorageService,
    private dataStore: DataStoreService
  ) {
    this.dataStore.loadSystemDate().then(() => {
      // if (appConfig.environment === "P") {
      //   this.dateTime = new Date(this.storageService.getItem("systemDate"));
      //   this.isStarted = true;
      //   interval(1000).subscribe(time => {
      //     this.dateTime = addSeconds(this.dateTime, 1);
      //   });

      //   this.dataStore.getOperationHour().subscribe(result => {
      //     result.validateResponse(
      //       () => {
      //         this.startTime = result['ReturnValue'].StartTime.split(":");
      //         this.endTime = result['ReturnValue'].EndTime.split(":");
      //         appConfig.startTime = result['ReturnValue'].StartTime;
      //         appConfig.endTime = result['ReturnValue'].EndTime;
      //         this.checkOpearatingHours();
      //       },
      //       () => {}
      //     );
      //   });
      // }
    });
  }

  checkOpearatingHours() {
    const starDateTime = new Date(this.dateTime);
    starDateTime.setHours(
      parseInt(this.startTime[0]),
      parseInt(this.startTime[1]),
      parseInt(this.startTime[2])
    );
    const endDateTime = new Date(this.dateTime);
    endDateTime.setHours(
      parseInt(this.endTime[0]),
      parseInt(this.endTime[1]),
      parseInt(this.endTime[2])
    );
    if (this.dateTime < starDateTime || this.dateTime > endDateTime) {
      this.router.navigate(["home"]);
    }
    return true;
  }
  canActivate(route: ActivatedRouteSnapshot) {
    return true;
    if (appConfig.environment === "P" && this.isStarted) {
      if (this.checkOpearatingHours()) {
        return true;
      }
    } else {
      return true;
    }
  }
}
