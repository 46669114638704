
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";


const routes: Routes = [
  
   {
    path: "",
    redirectTo:"pebt",
    pathMatch: "prefix"
  },
  {
    path: "pebt",
    loadChildren: () =>
      import("src/app/screens/pebt/pebt.module").then(
        (m) => m.PebtModule
      )
  },  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
