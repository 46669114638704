import { Directive, TemplateRef, ViewContainerRef, Input, ElementRef } from '@angular/core';

@Directive({
	selector: '[myDelay]'
})
export class MyDelayDirective {
	constructor(private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		//private element: ElementRef
		) {

		// const observer = new MutationObserver((mutations) => {
		// 	mutations.forEach((mutation: any) => {
		// 		console.log(mutation)

		// 	});
		// });

		// observer.observe(templateRef.elementRef.nativeElement, {
		// 	attributes: true,
		// 	childList: true,
		// 	characterData: true
		// });
	}
	@Input() set myDelay(value) {
		// let el = this.templateRef.elementRef.nativeElement;
		// el.lastChild.lastChild.innerText = el.title; 
		this.viewContainer.clear();
		setTimeout(() => {
			
			this.viewContainer.createEmbeddedView(this.templateRef);
		}, 1);
	}
}