import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ConfirmationType } from '@pebt/common';

import { IScreenInfo, ScreenFormComponent } from '@pebt/uibase';
import { AppConstants } from '@shared/constants/app-constants';
import { CommonService } from '@shared/services/common.service';
import { CustomGridColumn } from './custom-grid.model/custom-grid.model';


@Component({
  selector: 'custom-grid',
  templateUrl: './custom-grid.component.html',
  styleUrls: ['./custom-grid.component.scss']
})
export class CustomGridComponent extends ScreenFormComponent {

  screenInfo: IScreenInfo;

  onFormValueChange(data: any): void { }

  onDestroy(): void { }
  colWidth: number = 20;
  gridData: any[] = [];
  pageData: any[] = [];
  gridColumns: CustomGridColumn[];
  refm: string = "";
  needAccIcon: boolean = false;
  @Input() paginator: boolean = false;
  @Input() isTable: boolean = false;
  @Input() template: TemplateRef<HTMLDivElement>;
  @Input() rowsPerPage: number = 0;



  @Input() set columns(value) {
    if (value && value.length) {
      value.forEach(col => {
        if (col.hideOnTab == true || col.hideOnMobile == true) {
          this.needAccIcon = true;
        }
        if (!col.relativeWidth) {
          col.relativeWidth = 1;
        }
      });

      this.gridColumns = value;
      this.setCoulmnWidth();
    }
  }
  isMobileView: boolean = false;
  isTabView: boolean = false;

  @Input() isDynamicWidth: boolean = false;
  @Input() isEditable: boolean = true;
  @Output() editClick: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() deleteClick: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() linkClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() set dataSource(value) {

    if (value && value.length) {
      value.forEach(element => {
        element['showAccordion'] = false;
      });
      this.gridData = value;
      this.loadPage(0, 0)
    }
  }
  constructor(private commonService: CommonService,
    private appConstants: AppConstants) {
    super();
    this.setDevice();
  }
  a() {
    // 
  }
  onInitialize(): void { }
  onViewReady(): void {

    this.setCoulmnWidth()
  }

  setCoulmnWidth() {
    let addEditColumn = 0
    if (this.isEditable) {
      addEditColumn = 1
    }
    if (this.gridColumns) {

      if (!this.isDynamicWidth) {
        this.colWidth = 100 / (this.gridColumns.length + addEditColumn);
      }
      else {
        let colWidthCount = 0
        this.gridColumns.forEach(element => {
          colWidthCount += element.relativeWidth;
        });

        this.colWidth = 100 / (colWidthCount + addEditColumn);
      }

      if (this.isTabView) {
        let colCount = 0;
        this.gridColumns.forEach(element => {
          if (!element.hideOnTab) {
            colCount += element.relativeWidth;
          }
        });
        this.colWidth = 100 / (colCount + addEditColumn);
      }

      if (this.isMobileView) {
        let colCount = 0;
        this.gridColumns.forEach(element => {
          if (!element.hideOnMobile && !element.hideOnTab) {
            colCount += element.relativeWidth;
          }
        });

        this.colWidth = 100 / (colCount + addEditColumn);
      }


    }
  }

  getColWidth(col) {
    if (this.isMobileView || this.isTabView) {
      return this.colWidth;
    }
    return (this.isDynamicWidth == true) ? (col.relativeWidth * this.colWidth) : this.colWidth;
  }

  onEditClick(record) {
    this.editClick.emit(record)
  }
  onDeleteClick(record) {
    this.doOpenConfirm(
      () => {
        this.deleteClick.emit(record)
      },
      () => {

      },
      this.appConstants.APP.TITLE,
      this.appConstants.APP.RECORD_LOST_MSG,
      ConfirmationType.yesNo,
      () => {

      }
    );

  }
  onLinkClick(event, record, col) {
    this.linkClick.emit({ rowData: record, column: col })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDevice();
    this.setCoulmnWidth();
  }

  setDevice() {
    this.isTabView = this.commonService.isSmallDevice();
    this.isMobileView = this.commonService.isMobileDevice();
  }

  paginate(event) {

    this.loadPage(event.page, event.first)
  }

  loadPage(page, start) {
    if (this.paginator) {
      let index = (page * this.rowsPerPage);
      let length = (start + this.rowsPerPage)
      this.pageData = this.gridData.slice(index, length)
    } else {
      this.pageData = this.gridData;
    }
  }


}
