import {
  Component,
  ElementRef,
  HostListener,
  Renderer2,
  ViewChild
} from "@angular/core";
import {
  AlertMessageModel,
  ConfirmationType,
  FormError,
  FormGroupSection,
  MessageModel,
  UIMessageModel
} from "@pebt/common";
import { IScreenInfo, ScreenFormComponent } from "@pebt/uibase";
import { AppConstants } from "@shared/constants/app-constants";
import { RelatedFieldPipe } from "@shared/pipes/related-field.pipe";
import { appConfig } from "src/config";

@Component({
  selector: "app-error-panel",
  templateUrl: "./error-panel.component.html",
  styleUrls: ["./error-panel.component.scss"],
  providers: [RelatedFieldPipe]
})
export class ErrorPanelComponent extends ScreenFormComponent {
  @ViewChild("moreErrorlink") moreErrorlink;
  screenInfo: IScreenInfo = null;
  messageData: any[] = [];
  alertData: any[] = [];
  showErrorSideBar: boolean = false;
  showErrorBaner: boolean = false;
  messageOptions: { [key: string]: string | number }[] = [
    { label: "", value: "" }
  ];
  private allFormErrors = [];
  errorPanelPosition: string = "right";
  isMoreError: boolean = false;
  isAlert: boolean = false;
  activeTabIndex: number = 0;

  private formErrorIndex: number = -1;
  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    public appConstants: AppConstants
  ) {
    super();
    this.messageService.toggleErrorPanel$.subscribe((isShow: boolean) => {
      this.showErrorSideBar = isShow;
    });
  }
  @HostListener("document:keydown", ["$event"]) onKeyDown(event) {
    // M
    if (event.which === 27) {
      if (this.showErrorSideBar) {
        this.showErrorSideBar = false;

        return;
      }
    }
  }

  isMoreFormError() {
    if (
      (this.messageData &&
        this.messageData.length > 0 &&
        this.messageData[0].formErrrors &&
        this.messageData[0].formErrrors.length > 1) ||
      (this.messageData[0].messages && this.messageData[0].messages.length > 1)
    ) {
      this.isMoreError = true;

      return true;
    }
    this.isMoreError = false;

    return false;
  }
  onFormValueChange(data: string): void {}

  onInitialize(): void {
    this.rootFormGroup = this.buildFormGroup(
      {
        messageSearch: ""
      },
      FormGroupSection.errorSection
    );
    this.errorMessages = JSON.parse(this.storageService.getItem("EMSG")) || {
      E0076: "ENTER REQUIRED FIELDS"
    };
    this.messageService.showError$.subscribe((message: MessageModel | any) => {
      // if (this.messageService.showError$.observers.length > 1) {
      //   return;
      // }
      if (this.messageService["isNoticeDialog"]) {
        this.messageData = [];

        return;
      }
      this.isAlert = false;
      this.setAlert();
      this.activeTabIndex = 0;
      // if (this.messageService.dialogCount > 0) {
      //   return;
      // }
      this.errorMessages = JSON.parse(this.storageService.getItem("EMSG")) || {
        E0076: "ENTER REQUIRED FIELDS"
      };
      if (message instanceof FormError) {
        if (this.showErrorSideBar) {
          return;
        }
        if (message.Code === "H") {
          if (
            this.messageData &&
            this.messageData.length > 0 &&
            this.messageData[0] instanceof FormError
          ) {
            this.messageData = [];
          }

          return;
        }
        message.Description = this.errorMessages[message.Code];
        this.messageData = [message];
        this.showErrorSideBar = false;
        this.showErrorBaner = true;
      } else if (message instanceof UIMessageModel) {
        if (!message.formErrrors || message.formErrrors.length === 0) {
          return;
        }
        const formErrorData = Object.assign({}, message);
        const errorInfo = {
          type: "Error",
          Code: formErrorData.Code,
          Description: formErrorData.Description,
          formErrrors: [],
          RelatedField: ""
        };
        if (formErrorData.formErrrors.length === 1) {
          errorInfo.RelatedField = formErrorData.formErrrors[0].RelatedField;
          errorInfo.Description = formErrorData.formErrrors[0].Description;
          this.messageData = [errorInfo];
          if (errorInfo.RelatedField !== "" && errorInfo.Description !== "") {
            this.showErrorBaner = true;
          }

          return;
        }

        //errorInfo.formErrrors = this.processFormErrorMessage(message);
        errorInfo.formErrrors = message.formErrrors;

        this.allFormErrors = message.formErrrors;
        this.checkandSetError(errorInfo);
        this.showErrorBaner = true;
        if (this.isMoreError && this.moreErrorlink) {
          setTimeout(() => {
            this.moreErrorlink.nativeElement.focus();
          }, 0);
        }
      } else if (message instanceof AlertMessageModel) {
        // if (this.messageData.length > 0) {
        //   if (this.messageData[0] instanceof AlertMessageModel) {
        //   } else {
        //     this.cachedErrorMessage = Object.assign([], this.messageData);
        //   }
        // }
        // this.messageData = [message];
        this.activeTabIndex = 1;
        this.showErrorBaner = false;
        this.showSideBar();
      } else if (message instanceof MessageModel) {
        this.messageData = [message];
        this.showErrorSideBar = false;
        this.showErrorBaner = true;
      } else if (message instanceof Array) {
        if ((message as Array<any>).length === 0) {
          this.messageData = [];
          this.showErrorSideBar = false;
          this.showErrorBaner = false;
        } else {
          if (
            !this.checkIsNoDataFound(message) &&
            !this.checkUnauthorized(message)
          ) {
            this.messageData = message;
            this.showErrorSideBar = false;
            this.showErrorBaner = true;
          }
        }
      } else if (message.Code && message.Code.length > 0) {
        this.messageData = [message];
        this.showErrorBaner = true;
      }
    });
  }

  checkUnauthorized(status) {
    if (status && status.length > 0) {
      if (status[0].Code == "401") {
        this.showErrorSideBar = false;
        this.showErrorBaner = false;
        this.doOpenConfirm(
          () => {},
          null,
          this.appConstants.APP.TITLE,
          this.appConstants.APP.UNAUTHORIZED,
          ConfirmationType.okCancel
        );

        return true;
      }
    }
    return false;
  }

  checkIsNoDataFound(status) {
    if (status && status.length > 0) {
      if (status[0].Code == "E0012") {
        return true;
      }
    }
    return false;
  }

  onErrorPaneShow() {}
  showSideBar() {
    const sideBarElement = this.elRef.nativeElement.getElementsByClassName(
      "errorSidebar"
    )[0];
    this.renderer.removeClass(sideBarElement, "hidden");
    setTimeout(() => {
      this.showErrorSideBar = true;
    }, 0);
  }

  onerrorPanelHide() {
    if (this.isAlert && this.messageData.length > 0) {
      // this.messageData = this.cachedErrorMessage;
      this.showErrorBaner = true;
      this.isAlert = false;
    }
    const sideBarElement = this.elRef.nativeElement.getElementsByClassName(
      "errorSidebar"
    )[0];
    this.renderer.addClass(sideBarElement, "hidden");
  }
  hideBaner() {
    this.showErrorBaner = false;
    this.messageData = [];
  }
  setAlert() {
    const alertMsg = this.storageService.getItem("BROAD_CAST_MSG");
    if (alertMsg) {
      const alert = new AlertMessageModel();
      alert.messages = JSON.parse(alertMsg);
      this.alertData = [alert];
    } else {
      this.alertData = [];
    }
  }
  checkandSetError(error) {
    if (this.messageData.length === 0) {
      this.messageData = [error];
      this.formErrorIndex = 0;
    } else {
      let isFormErrorExists = false;
      for (
        let index = 0, length = this.messageData.length;
        index < length;
        index++
      ) {
        const message = this.messageData[index];
        if (message.formErrrors) {
          this.messageData[index] = error;
          isFormErrorExists = true;
          this.formErrorIndex = index;
          break;
        }
      }
      if (!isFormErrorExists) {
        this.formErrorIndex = -1;
        this.messageData = [error];
      }
    }
  }
  fnShowErrorPanel() {
    const msgOptions = [];

    this.allFormErrors.forEach((value) => {
      msgOptions.push({
        label: value.relatedField,
        value: value.relatedField
      });
    });
    this.messageOptions = msgOptions;

    for (
      let index = 0, length = this.messageData.length;
      index < length;
      index++
    ) {
      const message = this.messageData[index];
      if (message.formErrrors && !message["ordered"]) {
        message["ordered"] = true;
        message.formErrrors = this.processFormErrorMessage(message);
        break;
      }
    }

    this.setFormValue("", this.rootFormGroup.controls.messageSearch);
    setTimeout(() => {
      this.showSideBar();
      this.activeTabIndex = 0;
    }, 0);
  }

  messageClick(event: Event, data: FormError) {
    const labelElement: NodeListOf<HTMLLabelElement> = document.querySelectorAll(
      '[for="' + data.element + '"]'
    );
    const lastActiveElement = document.querySelectorAll(
      ".active.errorInfoPanelItem"
    );
    if (lastActiveElement.length > 0) {
      this.renderer.removeClass(lastActiveElement[0], "active");
    }
    this.renderer.addClass(event.currentTarget, "active");

    const activeLabelElement: HTMLLabelElement[] = [];
    for (let index = 0, length = labelElement.length; index < length; index++) {
      const element = labelElement[index];
      const elementText = element.innerText.replace("*", "").trim();
      if (elementText === data.RelatedField.trim()) {
        activeLabelElement.push(element);
      }
    }

    if (activeLabelElement.length === 1) {
      activeLabelElement[0].click();

      return;
    }

    const rowIndex = data.Row ? data.Row : 0;
    if (activeLabelElement.length > rowIndex) {
      (activeLabelElement[rowIndex] as HTMLLabelElement).click();
    }
    if (activeLabelElement.length === 0) {
      const element = document.getElementById(data.element);
      if (element && element.focus) {
        element.focus();
      }
    }
  }

  onMessageSearch(event) {
    if (this.formErrorIndex === -1) {
      return;
    }
    const searchValue = this.rootFormGroup.value.messageSearch.toLowerCase();

    if (searchValue.length > 0) {
      this.messageData[this.formErrorIndex].formErrrors = [];
      for (
        let msgIndex = 0, msgLength = this.allFormErrors.length;
        msgIndex < msgLength;
        msgIndex++
      ) {
        const element = this.allFormErrors[msgIndex];
        const fieldValue = element.RelatedField.toLowerCase();
        const descValue = element.Description.toLowerCase();
        if (
          fieldValue.indexOf(searchValue) !== -1 ||
          descValue.indexOf(searchValue) !== -1
        ) {
          this.messageData[this.formErrorIndex].formErrrors.push(element);
        }
      }
    } else {
      this.messageData[this.formErrorIndex].formErrrors = this.allFormErrors;
    }
  }

  private processFormErrorMessage(message: UIMessageModel) {
    const domErrorelements = document.getElementsByClassName(
      "ng-invalid ng-pristine"
    );

    let index = 0;
    const formErrrors = [];
    const gridErrrors = [];
    for (
      let errorIndex = 0, errorLength = message.formErrrors.length;
      errorIndex < errorLength;
      errorIndex++
    ) {
      const error = message.formErrrors[errorIndex];
      if (error.element) {
        if (error.element.indexOf("~") === -1) {
          formErrrors.push(error);
        } else {
          formErrrors.push({
            RelatedField: error.RelatedField,
            element: error.element.split("~")[0],
            Description: error.Description,
            row: error.element.split("~")[1]
          });
        }
      } else {
        gridErrrors.push(error);
      }
    }

    let newFormError = [];
    for (const key in domErrorelements) {
      if (domErrorelements.hasOwnProperty(key)) {
        const domElement = domErrorelements[key];
        const domAttrFieldName = domElement.attributes["formcontrolname"]
          ? domElement.attributes["formcontrolname"].value
          : domElement.attributes["id"]
          ? domElement.attributes["id"].value
          : "";

        if (domAttrFieldName) {
          const indexInForm = formErrrors.findIndex((formElement) => {
            if (formElement && formElement.element) {
              index++;

              return formElement.element === domAttrFieldName;
            }
          });
          if (indexInForm !== -1) {
            newFormError.push(formErrrors.splice(indexInForm, 1)[0]);
          }
        }
      }
    }
    if (formErrrors.length !== 0) {
      newFormError = newFormError.concat(formErrrors);
    }
    if (gridErrrors.length > 0) {
      newFormError = newFormError.concat(gridErrrors);
    }

    message.formErrrors = newFormError;

    return newFormError;
  }
  onViewReady(): void {
    const sideBarElement = this.elRef.nativeElement.getElementsByClassName(
      "errorSidebar"
    )[0];
    if (sideBarElement) this.renderer.addClass(sideBarElement, "hidden");
  }
  onDestroy(): void {}
}
