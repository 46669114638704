

<div class="translatePanel" [hidden]="!showTranslatePanel" [style.top.px]="transPanelTop"
  [style.right.px]="transPanelRight">
  <div class="text-right"> <button class="btn-blue translatePanelClose" (click)="TranslateClose()"><i
        class="fa fa-remove"></i> Close </button></div>
  <div id="google_translate_element"></div>

  <p class="translate_disclaimer pt-3 text-small">The State of NJ site may contain optional links, information, services
    and/or
    content from
    other websites operated by third parties that are provided as a convenience, such as Google™ Translate. Google™
    Translate is an online service for which the user pays nothing to obtain a purported language translation. The
    user
    is on notice that neither the State of NJ site nor its operators review any of the services, information and/or
    content from anything that may be linked to the State of NJ site for any reason. -<a
      href="https://nj.gov/nj/home/disclaimer.html" target="_blank"><strong>Read Full Disclaimer <i
          class="fa fa-link"></i></strong></a></p>
</div>


<router-outlet></router-outlet>
<lib-dialog header="Confirmation" icon="pi pi-exclamation-triangle"></lib-dialog>

<lib-confirm-dialog></lib-confirm-dialog>

<button title="Back to Top" class="scrollTopStyle" [ngClass]="{'show-scrollTop': showScrollBtn}" (click)="scrollToTop()"><i
    class="fa fa-long-arrow-up"></i></button>