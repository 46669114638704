import { Injectable } from "@angular/core";

@Injectable()
export class MessageConstants {
  // Error Message
  readonly EMSG0986: string = "E0986";
  readonly EMSG0187: string = "E0187";
  readonly EMSG0085: string = "E0085";
  readonly EMSG2408: string = "E2408";
  readonly EMSG0702: string = "E0702";
  readonly EMSG2397: string = "E2397";
  readonly EMSG0978: string = "E0978";
  readonly EMSG2035: string = "E2035";
  readonly EMSG2018: string = "E2018";
  readonly EMSG0979: string = "E0979";
  readonly EMSG1172: string = "E1172";
  readonly EMSG0601: string = "E0601";
  readonly EMSG0551: string = "E0551";
  readonly EMSG0520: string = "E0520";
  readonly EMSG2339: string = "E2339";
  readonly EMSG0436: string = "E0436";
  readonly EMSG2169: string = "E2169";
  readonly EMSG2056: string = "E2056";
  readonly EMSG1105: string = "E1105";
  readonly EMSG1270: string = "E1270";
  readonly EMSG0713: string = "E0713";
  readonly EMSG1040: string = "E1040";
  readonly EMSG1469: string = "E1469";
  readonly EMSG1122: string = "E1122";
  readonly EMSG1134: string = "E1134";
  readonly EMSG1135: string = "E1135";
  readonly EMSG0043: string = "E0043";
  readonly EMSG0836: string = "E0836";
  readonly EMSG1098: string = "E1098";
  readonly EMSG0982: string = "E0982";
  readonly EMSG0019: string = "E0019";
  readonly EMSG0618: string = "E0618";
  readonly EMSG1574: string = "E1574";
  readonly EMSG0076: string = "E0076";
  readonly EMSG0951: string = "E0951";
  readonly EMSG2192: string = "E2192";
  readonly EMSG2138: string = "E2138";
  readonly EMSG2308: string = "E2308";
  readonly EMSG2292: string = "E2292";
  readonly EMSG0869: string = "E0869";
  readonly EMSG2223: string = "E2223";
  readonly EMSG0073: string = "E0073";
  readonly EMSG2263: string = "E2263";
  readonly EMSG2307: string = "E2307";
  readonly EMSG2353: string = "E2353";
  readonly EMSG0862: string = "E0862";
  readonly EMSG0145: string = "E0145";
  readonly EMSG2065: string = "E2065";
  readonly EMSG0737: string = "E0737";
  readonly EMSG0239: string = "E0239";
  readonly EMSG2052: string = "E2052";
  readonly EMSG2329: string = "E2329";
  readonly EMSG2345: string = "E2345";
  readonly EMSG0053: string = "E0053";
  readonly EMSG2399: string = "E2399";
  readonly EMSG0945: string = "E0945";
  readonly EMSG2362: string = "E2362";
  readonly EMSG2360: string = "E2360";
  readonly EMSG0246: string = "E0246";
  readonly EMSG0259: string = "E0259";
  readonly EMSG1083: string = "E1083";
  readonly EMSG1988: string = "E1988";
  readonly EMSG2268: string = "E2268";
  readonly EMSG2234: string = "E2234";
  readonly EMSG2269: string = "E2269";
  readonly EMSG0879: string = "E0879";
  readonly EMSG2270: string = "E2270";
  readonly EMSG2420: string = "E2420";
  readonly EMSG0549: string = "E0549";
  readonly EMSG0044: string = "E0044";
  readonly EMSG0008: string = "E0008";
  readonly EMSG1073: string = "E1073";
  readonly EMSG0289: string = "E0289";
  readonly EMSG2091: string = "E2091";
  readonly EMSG1488: string = "E1488";
  readonly EMSG0840: string = "E0840";
  readonly EMSG2396: string = "E2396";
  readonly EMSG0930: string = "E0930";
  readonly EMSG0931: string = "E0931";
  readonly EMSG0929: string = "E0929";
  readonly EMSG2355: string = "E2355";
  readonly EMSG2356: string = "E2356";
  readonly EMSG2357: string = "E2357";
  readonly EMSG2358: string = "E2358";
  readonly EMSG2359: string = "E2359";
  readonly EMSG2361: string = "E2361";
  readonly EMSG0032: string = "E0032";
  readonly EMSG1234: string = "E1234";
  readonly EMSG0541: string = "E0541";
  readonly EMSG0152: string = "E0152";
  readonly EMSG1099: string = "E1099";

  readonly EMSG0292: string = "E0292";
  readonly EMSG2154: string = "E2154";
  readonly EMSG0066: string = "E0066";
  readonly EMSG2469: string = "E2469";
  readonly EMSG2474: string = "E2474";
  readonly EMSG2304: string = "E2304";
  readonly EMSG0439: string = "E0439";
  readonly EMSG2099: string = "E2099";
  readonly EMSG2073: string = "E2073";
  readonly EMSG0543: string = "E0543";
  readonly EMSG0542: string = "E0542";
  readonly EMSG0952: string = "E0952";
  readonly EMSG0906: string = "E0906";
  readonly EMSG0839: string = "E0839";

  // Information Message
  readonly IMSG0001: string = "I0001";
  readonly IMSG0002: string = "I0002";
  readonly IMSG0101: string = "I0101";
  readonly IMSG0015: string = "I0015";
  readonly IMSG0166: string = "I0166";
  readonly IMSG0094: string = "I0094";
  readonly IMSG0022: string = "I0022";
  readonly IMSG0093: string = "I0093";
  readonly IMSG0122: string = "I0122";
  readonly IMSG0170: string = "I0170";
  readonly IMSG0006: string = "I0006";
  readonly IMSG0132: string = "I0132";
  readonly IMSG0173: string = "I0173";
  readonly IMSG0168: string = "I0168";
  readonly IMSG0095: string = "I0095";
  readonly IMSG0096: string = "I0096";
  // Warning Message
  readonly WMSG0159: string = "W0159";
  readonly WMSG0211: string = "W0211";
  readonly WMSG0212: string = "W0212";
  readonly WMSG0219: string = "W0219";
  readonly WMSG0192: string = "W0192";
  readonly WMSG0180: string = "W0180";
  readonly WMSG0003: string = "W0003";
  readonly WMSG0131: string = "W0131";
  readonly WMSG0163: string = "W0163";
  readonly WMSG0218: string = "W0218";
  readonly WMSG0195: string = "W0195";
  readonly WMSG0200: string = "W0200";
  readonly WMSG0220: string = "W0220";
  readonly WMSG0221: string = "W0221";
  readonly WMSG0186: string = "W0186";
  readonly WMSG0187: string = "W0187";
  readonly EMSG2478: string = "E2478";
  readonly WMSG0224: string = "W0224";
  readonly IMSG0174: string = "I0174";
}
