import { Pipe, PipeTransform } from "@angular/core";
import { PebtConstants } from "@pebt/common";

@Pipe({ name: "zip" })
export class ZipPipe implements PipeTransform {
  transform(value: any): string {
    if (
      !value ||
      value === PebtConstants.NULL_INT ||
      value === PebtConstants.NULL_LONG
    ) {
      return "";
    }
    const postalCode = value.toString();
    if (postalCode.length === 9) {
      const zip1 = postalCode.slice(0, 5);
      const zip2 = postalCode.slice(5, postalCode.length);
      const zipCode = zip1 + "-" + zip2;

      return zipCode;
    }

    return value;
  }
}
