import { BaseModel, PebtConstants } from "@pebt/common";

export class AddressRecordData extends BaseModel {
  addrId: number = PebtConstants.NULL_INT;
  addrStdInd: boolean = false;
  addressTypeCode: string = "";
  cityName: string = "";
  countryCode: string = "";
  countryDesc: string = "";
  countyCode: string = "";
  countyName: string = "";
  line1Addr: string = "";
  line2Addr: string = "";
  postCode: string = "";
  stateCode: string = "";
}
