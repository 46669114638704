import { Injectable } from "@angular/core";

@Injectable()
export class AppConstants {
  static readonly delayTwoSec: number = 2;
  static readonly SESSION_EXPIRED: string = "USER SESSION EXPIRED";
  static readonly THREE_SEC_DELAY: number = 3000;
  static readonly TWO_SEC_DELAY: number = 2000;
  static readonly RECORDSPERPAGE: number = 10;

  readonly IND_YES: string = "Y";
  readonly IND_NO: string = "N";

  readonly STATE_NV: string = "NV";
  readonly COUNTRY: { [key: string]: string } = {
    US: "US",
    CANADA: "CA"
  };

  readonly access: { [key: string]: string } = {
    access: "accessInd",
    delete: "deleteInd",
    insert: "insertInd",
    select: "selectInd",
    update: "updateInd"
  };

  readonly MASK: { [key: string]: string } = {
    PHONE_US: "(999) 999-9999",
    PHONE_NONUS: "?999999999999999",
    ZIP_US: "99999?",//"99999?-9999",
    ZIP_NONUS: "?***************",
    SSN: "999-99-9999",
    FEIN: "99?-9999999",
    PHONE_STD: "9?99",
    PHONE_EXT: "999",
    RECEIPT_MASK: "99/99/9999-aaa-9999-999-999"
  };

  readonly APP: { [key: string]: string } = {
    TITLE: "MYNJHELPS",
    DATA_LOST_MSG: "All the record(s) will be deleted. Do you wish to continue?",
    RECORD_LOST_MSG: "This record will be deleted. Do you wish to continue?",
    SURE_MSG: "Are you sure you want to exit without creating an account?",
    ACCOUNT_MSG: "Your information will be lost. Are you sure you want to exit without creating an account?",
    SCREEN_WARNG: "Your information will be lost. Are you sure you want to continue?",
    ENTER_DATA: "Enter Required Data",
    ENTER_MISSING_DATA: "Some Required Details are missing. Please fill them before reviewing the Application.",
    UNFINSHED_TEXT: "If you are unable to finish the application at this time, you may submit it now. A caseworker will get in touch with you to complete the application process and discuss necessary documents to verify your information. We strongly encourage you to continue to complete the online application in order to serve you better and avoid any processing delays.",
    UNAUTHORIZED: "You have been logged out. This may have been caused by using more than one device or browser. To avoid this, only log in with one device or browser at a time."
  }

  readonly URL: { [key: string]: string } = {    
    "search":"/pebt/search",
    "result":"/pebt/result"
  }
}
